import React, { useEffect, useState, useCallback } from 'react';
import {
	Box,
	Typography,
	Paper,
	Divider,
	CircularProgress,
	Alert,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from '@mui/material';
import PatientTab from '../../../components/patientTab';
import axios from 'axios';
import InfoIcon from '@mui/icons-material/Info'; // Info icon for key points
import WarningIcon from '@mui/icons-material/Warning'; // Warning icon for risk factors
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Check icon for actionable insights
import debounce from 'lodash/debounce';

const Insight = ({ patientId = 'kuduu-384743' }) => {
	const [insights, setInsights] = useState('');
	const [loading, setLoading] = useState(true);

	const aiEndpoint = process.env.REACT_APP_AI_ENDPOINT;

	// Function to fetch insights
	const fetchInsights = async (id) => {
		try {
			const response = await axios.get(
				`${aiEndpoint}/ai-insight/${id}`
			);
			setInsights(response.data[0]?.content || '');
		} catch (error) {
			console.error('Error fetching insights:', error);
		} finally {
			setLoading(false);
		}
	};

	// Create a debounced version of fetchInsights
	const debouncedFetchInsights = useCallback(
		debounce((id) => fetchInsights(id), 500), // Adjust debounce delay if necessary
		[]
	);

	useEffect(() => {
		setLoading(true); // Show loading spinner while fetching
		debouncedFetchInsights(patientId);

		// Clean up the debounce function on unmount
		return () => {
			debouncedFetchInsights.cancel();
		};
	}, [debouncedFetchInsights, patientId]);

	// Helper function to parse markdown and extract specific sections
	const extractSection = (content, sectionTitle) => {
		const regex = new RegExp(
			`${sectionTitle}:([\\s\\S]*?)(?=\\n\\n\\d+\\.|\\n\\n$|$)`,
			'i'
		);
		const match = content.match(regex);
		return match ? match[1].replace(/\*/g, '').trim() : null;
	};

	// Render Medication Table based on assistant response
	const renderMedicationsTable = (medications) => {
		if (!medications) return null;

		const medicationRows = medications
			.split('\n')
			.filter((row) => row)
			.map((row, index) => {
				const [medication, dosage, purpose, sideEffects] = row
					.split('|')
					.map((cell) => cell.trim());
				return (
					<TableRow key={index}>
						<TableCell>{medication}</TableCell>
						<TableCell>{dosage}</TableCell>
						<TableCell>{purpose}</TableCell>
						<TableCell>{sideEffects}</TableCell>
					</TableRow>
				);
			});

		return (
			<TableContainer component={Paper} sx={{ mb: 4 }}>
				<Table aria-label="medication-table">
					<TableBody>
						{medicationRows.length > 0 ? (
							medicationRows
						) : (
							<TableRow>
								<TableCell colSpan={4}>No medications listed.</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		);
	};

	if (loading) {
		return (
			<Box
				display="flex"
				flexDirection="column" // Stack spinner and text vertically
				justifyContent="center"
				alignItems="center"
				position="absolute" // Use absolute positioning
				top="45%" 
				left="40%"
				sx={{
					textAlign: "center"
				}}
			>
				<CircularProgress />
				<Typography 
					variant="h6" 
					sx={{
						mt: 2, // Adds some spacing below the spinner
						background: 'linear-gradient(90deg, #914DDC, #FF4D4D)',
						WebkitBackgroundClip: 'text',
						WebkitTextFillColor: 'transparent',
					}}
				>
					AI is Processing Health Data for Insights...
				</Typography>
			</Box>
		);
	}
	
	// Extract different sections of the insights dynamically
	const medicalHistory = extractSection(insights, 'Medical History Overview');
	const medications = extractSection(
		insights,
		'Current Medications and Treatments'
	);
	const labResults = extractSection(
		insights,
		'Recent Lab Results and Diagnostic Data'
	);
	const lifestyle = extractSection(
		insights,
		'Lifestyle and Behavioral Factors'
	);
	const riskFactors = extractSection(
		insights,
		'Potential Risk Factors and Complications'
	);
	const predictiveHealth = extractSection(insights, 'Predictive Health Issues');
	const providerRecommendations = extractSection(
		insights,
		'Recommendations for Healthcare Providers'
	);
	const actionableInsights = extractSection(
		insights,
		'Actionable Insights for Patient Self-Care'
	);

	return (
		<PatientTab>
			<Paper
				sx={{ p: 4, mb: 4, backgroundColor: '#f9f9f9', borderRadius: '8px' }}
			>
				<Typography
					variant="h4"
					gutterBottom
					sx={{ fontWeight: 'bold', mb: 2 }}
				>
					Patient Insights
				</Typography>
				<Divider sx={{ mb: 3 }} />

				{/* Render Medical History Section */}
				{medicalHistory && (
					<>
						<Typography
							variant="h5"
							sx={{ fontWeight: 'bold', mb: 2, color: '#1976d2' }}
						>
							Medical History Overview
						</Typography>
						<Typography variant="body1" sx={{ mb: 2 }}>
							{medicalHistory}
						</Typography>
					</>
				)}

				{/* Render Medications Section */}
				{medications && (
					<>
						<Typography
							variant="h5"
							sx={{ fontWeight: 'bold', mb: 2, color: '#1976d2' }}
						>
							Current Medications and Treatments
						</Typography>
						{renderMedicationsTable(medications)}
					</>
				)}

				{/* Render Lab Results Section */}
				{labResults && (
					<>
						<Typography
							variant="h5"
							sx={{ fontWeight: 'bold', mb: 2, color: '#1976d2' }}
						>
							Recent Lab Results and Diagnostic Data
						</Typography>
						<Typography variant="body1" sx={{ mb: 2 }}>
							{labResults}
						</Typography>
					</>
				)}

				{/* Render Lifestyle Section */}
				{lifestyle && (
					<>
						<Typography
							variant="h5"
							sx={{ fontWeight: 'bold', mb: 2, color: '#1976d2' }}
						>
							Lifestyle and Behavioral Factors
						</Typography>
						<Typography variant="body1" sx={{ mb: 2 }}>
							{lifestyle}
						</Typography>
					</>
				)}

				{/* Render Risk Factors Section with Alert */}
				{riskFactors && (
					<Alert severity="warning" icon={<WarningIcon />}>
						<Typography variant="body1">{riskFactors}</Typography>
					</Alert>
				)}

				{/* Render Predictive Health Issues */}
				{predictiveHealth && (
					<>
						<Typography
							variant="h5"
							sx={{ fontWeight: 'bold', mb: 2, color: '#1976d2' }}
						>
							Predictive Health Issues
						</Typography>
						<Typography variant="body1" sx={{ mb: 2 }}>
							{predictiveHealth}
						</Typography>
					</>
				)}

				{/* Render Recommendations for Providers */}
				{providerRecommendations && (
					<>
						<Typography
							variant="h5"
							sx={{ fontWeight: 'bold', mb: 2, color: '#1976d2' }}
						>
							Recommendations for Healthcare Providers
						</Typography>
						<Typography variant="body1" sx={{ mb: 2 }}>
							{providerRecommendations}
						</Typography>
					</>
				)}

				{/* Render Actionable Insights for Patient */}
				{actionableInsights && (
					<Alert severity="info" icon={<CheckCircleIcon />}>
						<Typography variant="body1">{actionableInsights}</Typography>
					</Alert>
				)}
			</Paper>
		</PatientTab>
	);
};

export default Insight;
