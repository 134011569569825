// components/tabs/labResults.js

import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Button } from '@mui/material';
import PatientTab from '../../../components/patientTab';

const LabResults = ({ patient, setActiveTab }) => {
  const labResults = patient.labResults || {};

  const handleViewXray = (xrayDate) => {
    setActiveTab(4); // the X-rays tab is at index 4
  };

  return (
    <PatientTab label="Lab Results">
      <Grid container spacing={3}>
        {Object.keys(labResults).map((testType) =>
          labResults[testType].map((result, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Card sx={{ minHeight: '150px' }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {`${testType} - ${result}`}
                  </Typography>
                  {testType === 'imagingResults' && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleViewXray(result.date)}
                      sx={{ mt: 2 }}
                    >
                      View X-ray
                    </Button>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
    </PatientTab>
  );
};

export default LabResults;
