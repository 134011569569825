import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Avatar, Typography, useMediaQuery } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { sidebarLinks } from '../constants';
import Logo from '../assets/kuduu_logo2.png';
import { useTheme } from '@mui/material/styles';
import Footer from './sidebarFooter';

const Sidebar = ({ user }) => {
  const theme = useTheme();
  const isWideScreen = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isWideScreen ? 275 : 80,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: isWideScreen ? 275 : 80, boxSizing: 'border-box' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
        <ListItem component={Link} to="/" sx={{ mb: 5, mr: 6, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
          <Avatar src={Logo} alt="Kuduu" sx={{ width: 80, height: 75 }} />
          {isWideScreen && (
            <Typography variant="h4" sx={{ color: 'black', mt: 1, alignItems: 'center', fontFamily: 'CustomFont', ml: 6 }}>
              Kuduu
            </Typography>
          )}
        </ListItem>
        {sidebarLinks.map((item) => {
          const isActive = location.pathname === `/demo${item.route}` || location.pathname.startsWith(`/demo${item.route}/`);
          return (
            <ListItem
              button
              key={item.title}
              component={Link}
              to={`/demo${item.route}`}
              sx={{
                justifyContent: 'center',
                py: isWideScreen ? 1 : 2,
                flexDirection: isWideScreen ? 'row' : 'column',
                alignItems: 'center',
                background: isActive ? 'linear-gradient(90deg, #1E3A8A , #3B82F6)' : 'none',
                color: isActive ? 'white' : 'inherit',
                borderRadius: isWideScreen && isActive ? '5px' : '0',
                width: isWideScreen ? '90%' : 'auto',
                height: isWideScreen && isActive ? '50px' : 'auto',
                marginBottom: isWideScreen ? '10px' : '0',
                transition: 'all 0.3s ease-in-out',
                gap: isWideScreen ? 2 : 0,
              }}
            >
              <ListItemIcon sx={{ minWidth: 'auto', justifyContent: 'center', color: isActive ? 'white' : 'black' }}>
                {item.icon}
              </ListItemIcon>
              {isWideScreen && (
                <ListItemText primary={item.title} sx={{ textAlign: 'center', fontSize: '0.75rem', color: isActive ? 'white' : 'inherit' }} />
              )}
            </ListItem>
          );
        })}
      </List>
      <Footer user={user} />
    </Drawer>
  );
};

export default Sidebar;
