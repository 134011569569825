
export const activitiesData = [
    {
      id: '1',
      name: 'Blood Test',
      details: 'Routine blood test',
      date: '2024-06-01T12:00:00Z',
      status: 'Completed'
    },
    {
      id: '2',
      name: 'MRI Scan',
      details: 'Head MRI',
      date: '2024-06-02T09:00:00Z',
      status: 'Pending'
    },
    {
      id: '3',
      name: 'X-Ray',
      details: 'Chest X-ray to check for pneumonia',
      date: '2024-06-03T14:30:00Z',
      status: 'Completed'
    },
    {
      id: '4',
      name: 'Physical Therapy',
      details: 'Session for shoulder pain',
      date: '2024-06-04T10:00:00Z',
      status: 'In Progress'
    },
    {
      id: '5',
      name: 'Consultation',
      details: 'Follow-up appointment with Dr. Smith',
      date: '2024-06-05T11:00:00Z',
      status: 'Scheduled'
    },
    {
      id: '6',
      name: 'Ultrasound',
      details: 'Abdominal ultrasound for pain investigation',
      date: '2024-06-06T08:00:00Z',
      status: 'Completed'
    },
    {
      id: '7',
      name: 'Vaccination',
      details: 'Annual flu vaccine',
      date: '2024-06-07T13:00:00Z',
      status: 'Completed'
    },
    {
      id: '8',
      name: 'Blood Pressure Check',
      details: 'Routine blood pressure monitoring',
      date: '2024-06-08T15:00:00Z',
      status: 'Pending'
    },
    {
      id: '9',
      name: 'Eye Exam',
      details: 'Routine eye examination',
      date: '2024-06-09T09:30:00Z',
      status: 'Scheduled'
    },
    {
      id: '10',
      name: 'EKG',
      details: 'Electrocardiogram to check heart function',
      date: '2024-06-10T10:45:00Z',
      status: 'Completed'
    },
    {
      id: '11',
      name: 'Surgery Consultation',
      details: 'Initial consultation for knee surgery',
      date: '2024-06-11T12:00:00Z',
      status: 'Scheduled'
    },
    {
      id: '12',
      name: 'Dietitian Appointment',
      details: 'Meeting with dietitian for weight management plan',
      date: '2024-06-12T14:00:00Z',
      status: 'Scheduled'
    },
    {
      id: '13',
      name: 'Dentist Appointment',
      details: 'Routine dental check-up',
      date: '2024-06-13T09:00:00Z',
      status: 'Completed'
    },
    {
      id: '14',
      name: 'Hearing Test',
      details: 'Routine hearing examination',
      date: '2024-06-14T11:30:00Z',
      status: 'Pending'
    },
    {
      id: '15',
      name: 'Allergy Test',
      details: 'Skin test for allergies',
      date: '2024-06-15T08:15:00Z',
      status: 'Completed'
    }
  ];