import React from 'react';
import {
	DashboardOutlinedIcon,
	Groups2OutlinedIcon,
	SettingsOutlinedIcon,
	LogoutIcon,
	AssessmentOutlinedIcon,
	NotificationsOutlinedIcon,
    HelpOutlineIcon,
	IntegrationInstructionsOutlinedIcon
} from '../assets/icons';

export const sidebarLinks = [
	{
		title: 'Dashboard',
		icon: <DashboardOutlinedIcon />,
		route: '/dashboard',
	},
	{
		title: 'Patients',
		icon: <Groups2OutlinedIcon />,
		route: '/patients',
	},
	{
		title: 'Reports',
		icon: <AssessmentOutlinedIcon />,
		route: '/reports',
	},
	{
		title: 'Notifications',
		icon: <NotificationsOutlinedIcon />,
		route: '/notifications',
	},
	{
		title: 'Integrations',
		icon: <IntegrationInstructionsOutlinedIcon />,
		route: '/integrations',
	},
	{
		title: 'Settings',
		icon: <SettingsOutlinedIcon />,
		route: '/settings',
	},
	{
		title: 'Help',
		icon: <HelpOutlineIcon />,
		route: '/help',
	},
];
