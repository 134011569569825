import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Groups2Icon from '@mui/icons-material/Groups2';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HelpIcon from '@mui/icons-material/Help';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import AssistantIcon from '@mui/icons-material/Assistant'; 

export {
    LockIcon,
    Visibility,
    VisibilityOff,
    DashboardIcon,
    Groups2Icon,
    SettingsIcon,
    LogoutIcon,
    NotificationsIcon,
    AssessmentIcon,
    HelpIcon,
    DashboardOutlinedIcon,
    Groups2OutlinedIcon,
    SettingsOutlinedIcon,
    LogoutOutlinedIcon,
    NotificationsOutlinedIcon,
    AssessmentOutlinedIcon,
    HelpOutlineIcon,
    IntegrationInstructionsIcon,
    IntegrationInstructionsOutlinedIcon,
    CloseOutlinedIcon,
    ChatIcon,
    CloseIcon,
    DeleteIcon,
    DownloadIcon,
    RemoveIcon,
    CloseFullscreenIcon,
    AssistantIcon,

}