import React, { useState } from 'react';
import { Box, Typography, TextField, MenuItem, Select, FormControl, InputLabel, Button, Grid } from '@mui/material';

const OrganizationInfo = ({ onNext }) => {
  const [organizationName, setOrganizationName] = useState('');
  const [organizationType, setOrganizationType] = useState('');
  const [address, setAddress] = useState({ street: '', city: '', state: '', zip: '', country: '' });
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [ehrSystem, setEhrSystem] = useState('');
  const [organizationSize, setOrganizationSize] = useState('');

  const handleNext = () => {
    // Add validation as needed
    onNext();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="h5">Organization Information</Typography>
      
      <TextField
        label="Organization Name"
        variant="outlined"
        fullWidth
        value={organizationName}
        onChange={(e) => setOrganizationName(e.target.value)}
      />

      <FormControl fullWidth variant="outlined">
        <InputLabel>Organization Type</InputLabel>
        <Select value={organizationType} onChange={(e) => setOrganizationType(e.target.value)} label="Organization Type">
          <MenuItem value="Hospital">Hospital</MenuItem>
          <MenuItem value="Clinic">Clinic</MenuItem>
          <MenuItem value="Non-Profit">Non-Profit</MenuItem>
        </Select>
      </FormControl>

      <Typography variant="h6">Address</Typography>
      <TextField label="Street Address" fullWidth value={address.street} onChange={(e) => setAddress({ ...address, street: e.target.value })} />
      <Grid container spacing={2}>
        <Grid item xs={6}><TextField label="City" fullWidth value={address.city} onChange={(e) => setAddress({ ...address, city: e.target.value })} /></Grid>
        <Grid item xs={3}><TextField label="State" fullWidth value={address.state} onChange={(e) => setAddress({ ...address, state: e.target.value })} /></Grid>
        <Grid item xs={3}><TextField label="ZIP" fullWidth value={address.zip} onChange={(e) => setAddress({ ...address, zip: e.target.value })} /></Grid>
      </Grid>
      <TextField label="Country" fullWidth value={address.country} onChange={(e) => setAddress({ ...address, country: e.target.value })} />

      <Typography variant="h6">Primary Contact</Typography>
      <TextField label="Contact Name" fullWidth value={contactName} onChange={(e) => setContactName(e.target.value)} />
      <TextField label="Contact Email" fullWidth value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} />
      <TextField label="Contact Phone" fullWidth value={contactPhone} onChange={(e) => setContactPhone(e.target.value)} />

      <FormControl fullWidth variant="outlined">
        <InputLabel>Organization Size</InputLabel>
        <Select value={organizationSize} onChange={(e) => setOrganizationSize(e.target.value)} label="Organization Size">
          <MenuItem value="Small">Small (&lt;50 employees)</MenuItem>
          <MenuItem value="Medium">Medium (50-500 employees)</MenuItem>
          <MenuItem value="Large">Large (&gt;500 employees)</MenuItem>
        </Select>
      </FormControl>

    </Box>
  );
};

export default OrganizationInfo;
