import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import PatientTab from '../../../components/patientTab';

const Medication = ({ patient }) => {
  const medications = patient.detailedMedications || [];

  return (
    <PatientTab label="Medications">
      <Grid container spacing={3}>
        {medications.map((medication, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card sx={{ minHeight: '200px' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>{medication.name}</Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  <strong>Dosage:</strong> {medication.dosage}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  <strong>Frequency:</strong> {medication.frequency}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  <strong>Prescribing Physician:</strong> {medication.prescribingPhysician}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  <strong>Start Date:</strong> {medication.startDate}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  <strong>End Date:</strong> {medication.endDate || 'Ongoing'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </PatientTab>
  );
};

export default Medication;
