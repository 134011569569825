import React from 'react';
import { TextField, Grid, Typography, Button, Box } from '@mui/material';
import PatientTab from '../../../components/patientTab';

const GeneralInfo = ({ patient }) => {

  const formatSSN = (ssn) => `***-**-${ssn.slice(-4)}`;

  return (
    <PatientTab label="General Information">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Basic Information</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Name" value={patient.name} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Date of Birth" value={patient.dob} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Age" value={patient.age} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Gender" value={patient.gender} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="SSN" value={formatSSN(patient.ssn)} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Blood Type" value={patient.bloodType} variant="outlined" disabled />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Contact Information</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Phone" value={patient.contact.phone} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Email" value={patient.contact.email} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth label="Address" value={patient.contact.address} variant="outlined" disabled />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Insurance Information</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Provider" value={patient.insurance.provider} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Policy Number" value={patient.insurance.policyNumber} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Coverage Details" value={patient.insurance.coverageDetails} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Contact" value={patient.insurance.contact} variant="outlined" disabled />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Emergency Contact</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Name" value={patient.emergencyContact.name} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Relationship" value={patient.emergencyContact.relationship} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Phone" value={patient.emergencyContact.phone} variant="outlined" disabled />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Health Information</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Height" value={patient.height} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Weight" value={patient.weight} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Health Issues" value={patient.healthIssues.join(', ')} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Operations" value={patient.operations.join(', ')} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Medications" value={patient.medications.join(', ')} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Allergies" value={patient.allergies.join(', ')} variant="outlined" disabled />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Visit Information</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Last Visit" value={patient.lastVisit} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Status" value={patient.status} variant="outlined" disabled />
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button variant="contained" color="primary">Start Visit</Button>
            <Button variant="outlined" sx={{ ml: 2 }}>Close</Button>
          </Box>
        </Grid>
      </Grid>
    </PatientTab>
  );
};

export default GeneralInfo;
