import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Line } from 'react-chartjs-2';

const UsageMetrics = ({ activeUsers, sessionsPerDay, avgSessionDuration, sessionTrends }) => {
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'], // Example labels
    datasets: [
      {
        label: 'Session Trends',
        data: sessionTrends,
        fill: false,
        backgroundColor: 'rgba(153,102,255,1)',
        borderColor: 'rgba(153,102,255,1)',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 10, 
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 10, 
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 10, 
          },
        },
      },
    },
  };

  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Usage Metrics
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {/* <Typography variant="body2">Active Users: {activeUsers}</Typography> */}
        </Grid>
        <Grid item xs={12}>
          {/* <Typography variant="body2">Sessions per Day: {sessionsPerDay}</Typography> */}
        </Grid>
        <Grid item xs={12}>
          {/* <Typography variant="body2">Average Session Duration: {avgSessionDuration} minutes</Typography> */}
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: 200 }}>
            <Line data={data} options={options} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default UsageMetrics;
