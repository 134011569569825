import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Paper } from '@mui/material';
import PatientTab from '../../../components/patientTab';

const MentalHealth = ({ patient }) => {
  const mentalHealth = patient.mentalHealth || {};

  return (
    <PatientTab label="Mental Health">
      <Paper sx={{ p: 2 }}>
        <List>
          <ListItem>
            <ListItemText primary="Diagnoses" secondary={mentalHealth.diagnoses.join(', ')} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Therapy Sessions" secondary={mentalHealth.therapySessions.join(', ')} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Medications" secondary={mentalHealth.medications.join(', ')} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Psychiatrist Contact" secondary={mentalHealth.psychiatristContact || 'N/A'} />
          </ListItem>
        </List>
      </Paper>
    </PatientTab>
  );
};

export default MentalHealth;