import athenaLogo from '../assets/integrationLogos/athenahealth-ehr.png';
import epicLogo from '../assets/integrationLogos/Epic.png';
import cernerLogo from '../assets/integrationLogos/cerner_emr_logo.png';  
import allscriptsLogo from '../assets/integrationLogos/Allscripts-Logo.jpg'; 
import nextgenLogo from '../assets/integrationLogos/nextgen_logo.png'; 
import meditechLogo from '../assets/integrationLogos/meditech_logo.png';  

export const integrations = [
    {
      name: 'Athena',
      description: 'Connect to Athena for seamless integration with your healthcare data.',
      logo: athenaLogo, 
      status: 'Connected',
    },
    {
      name: 'Epic',
      description: 'Integrate with Epic for comprehensive healthcare solutions.',
      logo: epicLogo, 
      status: 'Not Connected',
    },
    {
      name: 'Cerner',
      description: 'Leverage Cerner for advanced EHR solutions with high interoperability.',
      logo: cernerLogo,
      status: 'Not Connected',
    },
    {
      name: 'Allscripts',
      description: 'Allscripts offers solutions for both small practices and large health systems.',
      logo: allscriptsLogo,
      status: 'Not Connected',
    },
    {
      name: 'NextGen',
      description: 'NextGen provides integrated solutions for ambulatory care and population health management.',
      logo: nextgenLogo,
      status: 'Not Connected',
    },
    {
      name: 'Meditech',
      description: 'Meditech focuses on improving efficiency and patient safety across healthcare systems.',
      logo: meditechLogo,
      status: 'Not Connected',
    },
];