import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DiagnosisStatistics = ({ totalDiagnoses, commonDiagnoses, accuracyRate, diagnosisTrends }) => {
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'], // Example labels
    datasets: [
      {
        label: 'Diagnosis Trends',
        data: diagnosisTrends,
        fill: false,
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(75,192,192,1)',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 10, // Adjust the font size of the legend
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 10, // Adjust the font size of the x-axis labels
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 10, // Adjust the font size of the y-axis labels
          },
        },
      },
    },
  };

  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Diagnosis Statistics
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {/* <Typography variant="body2">Total Diagnoses: {totalDiagnoses}</Typography> */}
        </Grid>
        <Grid item xs={12}>
          {/* <Typography variant="body2">Common Diagnoses: {commonDiagnoses.join(', ')}</Typography> */}
        </Grid>
        <Grid item xs={12}>
          {/* <Typography variant="body2">Accuracy Rate: {accuracyRate}%</Typography> */}
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: 200 }}>
            <Line data={data} options={options} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default DiagnosisStatistics;
