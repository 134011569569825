import {
  xray01, xray02, xray03, xray04, xray05, xray06, xray07, xray08, xray09, xray10,
  xray11, xray12, xray13, xray14, xray15, xray16, xray17, xray18, xray19, xray20,
  xray21, xray22, xray23, xray24, xray25, xray26, xray27, xray28, xray29, xray30,
  xray31, xray32, xray33, xray34, xray35, xray36, xray37, xray38, xray39, xray40,
  xray41, xray42, xray43, xray44, xray45, xray46, xray47, xray48, xray49, xray50,
  xray51, xray52, xray53, xray54, xray55, xray56, xray57, xray58, xray59, xray60,
  xray61, xray62, xray63, xray64, xray65, xray66, xray67, xray68, xray69, xray70,
  xray71
} from './xrays';

export const patientsData = [
  {
    id: '1',
    name: 'John Doe',
    age: 45,
    gender: 'Male',
    dob: '1979-04-22',
    ssn: '123-45-6789',
    lastVisit: '2024-06-10',
    status: 'Active',
    healthIssues: ['Diabetes', 'High Blood Pressure'],
    operations: ['Appendectomy (2005)', 'Knee Surgery (2015)'],
    medications: ['Metformin', 'Lisinopril'],
    allergies: ['Peanuts', 'Penicillin'],
    height: '5ft 10in',
    weight: '180 lbs',
    bloodType: 'O+',
    insurance: {
      provider: 'HealthCare Inc.',
      policyNumber: 'HC123456789',
      coverageDetails: 'Full coverage',
      contact: '800-123-4567'
    },
    medicalHistory: {
      pastIllnesses: ['Chickenpox'],
      familyHistory: ['Heart Disease (father)', 'Diabetes (mother)'],
      immunizations: ['MMR', 'Tetanus'],
      previousHospitalizations: ['2002 - Pneumonia']
    },
    lifestyle: {
      smokingStatus: 'Non-smoker',
      alcoholConsumption: 'Occasional',
      exerciseRoutine: 'Weekly',
      dietaryPreferences: 'Vegetarian'
    },
    regularCheckups: {
      primaryCarePhysician: 'Dr. Smith',
      nextCheckup: '2024-12-10',
      lastPhysicalExam: '2023-06-10',
      screeningTests: ['Colonoscopy (2022)', 'Mammogram (2021)']
    },
    detailedMedications: [
      {
        name: 'Metformin',
        dosage: '500mg',
        frequency: 'Twice daily',
        prescribingPhysician: 'Dr. Brown',
        startDate: '2015-06-10',
        endDate: null
      },
      {
        name: 'Lisinopril',
        dosage: '10mg',
        frequency: 'Once daily',
        prescribingPhysician: 'Dr. Green',
        startDate: '2018-04-15',
        endDate: null
      }
    ],
    labResults: {
      bloodTests: ['2023-05-01', '2024-04-01'],
      imagingResults: [
        {
          date: '2023-06-10',
          description: 'X-ray of the chest.',
          imageUrl: xray01,
          imageName: "xray01"
        },
        {
          date: '2024-01-15',
          description: 'X-ray of the arm.',
          imageUrl: xray02,
          imageName: "xray02"
        },
        {
          date: '2023-03-05',
          description: 'X-ray of the leg.',
          imageUrl: xray03,
          imageName: "xray03"
        },
        {
          date: '2024-05-10',
          description: 'X-ray of the head.',
          imageUrl: xray04,
          imageName: "xray04"
        }
      ],
      biopsyResults: [],
      otherDiagnostics: []
    },
    mentalHealth: {
      diagnoses: ['Anxiety'],
      therapySessions: ['2024-01-10', '2024-03-10'],
      medications: ['Alprazolam'],
      psychiatristContact: 'Dr. Miller - 555-6789'
    },
    emergencyContact: {
      name: 'Jane Doe',
      relationship: 'Spouse',
      phone: '555-8765'
    },
    contact: {
      phone: '555-1234',
      email: 'john.doe@example.com',
      address: '123 Main St, Anytown, USA'
    },
    avatar: ''
  },
  {
    id: '2',
    name: 'Jane Smith',
    age: 30,
    gender: 'Female',
    dob: '1994-08-15',
    ssn: '234-56-7890',
    lastVisit: '2024-06-15',
    status: 'Inactive',
    healthIssues: ['Asthma'],
    operations: [],
    medications: ['Albuterol'],
    allergies: ['None'],
    height: '5ft 5in',
    weight: '130 lbs',
    bloodType: 'A-',
    insurance: {
      provider: 'HealthFirst',
      policyNumber: 'HF234567890',
      coverageDetails: 'Partial coverage',
      contact: '800-234-5678'
    },
    medicalHistory: {
      pastIllnesses: ['Bronchitis'],
      familyHistory: ['Asthma (mother)'],
      immunizations: ['Flu Shot'],
      previousHospitalizations: ['2010 - Asthma Attack']
    },
    lifestyle: {
      smokingStatus: 'Non-smoker',
      alcoholConsumption: 'Never',
      exerciseRoutine: 'Daily',
      dietaryPreferences: 'None'
    },
    regularCheckups: {
      primaryCarePhysician: 'Dr. Johnson',
      nextCheckup: '2024-11-15',
      lastPhysicalExam: '2023-06-15',
      screeningTests: ['None']
    },
    detailedMedications: [
      {
        name: 'Albuterol',
        dosage: '90mcg',
        frequency: 'As needed',
        prescribingPhysician: 'Dr. Johnson',
        startDate: '2010-05-15',
        endDate: null
      }
    ],
    labResults: {
      bloodTests: ['2023-06-15'],
      imagingResults: [
        {
          date: '2023-06-15',
          description: 'Lung Function Test',
          imageUrl: xray05
        },
        {
          date: '2024-03-10',
          description: 'X-ray of the chest.',
          imageUrl: xray06
        },
        {
          date: '2023-08-21',
          description: 'X-ray of the abdomen.',
          imageUrl: xray07
        },
        {
          date: '2024-01-05',
          description: 'X-ray of the pelvis.',
          imageUrl: xray08
        }
      ],
      biopsyResults: [],
      otherDiagnostics: []
    },
    mentalHealth: {
      diagnoses: [],
      therapySessions: [],
      medications: [],
      psychiatristContact: null
    },
    emergencyContact: {
      name: 'John Smith',
      relationship: 'Brother',
      phone: '555-6789'
    },
    contact: {
      phone: '555-5678',
      email: 'jane.smith@example.com',
      address: '456 Oak St, Anytown, USA'
    },
    avatar: ''
  },
  {
    id: '3',
    name: 'Alice Johnson',
    age: 50,
    gender: 'Female',
    dob: '1974-11-30',
    ssn: '345-67-8901',
    lastVisit: '2024-06-05',
    status: 'Active',
    healthIssues: ['Hypothyroidism'],
    operations: ['Thyroidectomy (2010)'],
    medications: ['Levothyroxine'],
    allergies: ['Shellfish'],
    height: '5ft 6in',
    weight: '150 lbs',
    bloodType: 'B+',
    insurance: {
      provider: 'Medicare',
      policyNumber: 'MC345678901',
      coverageDetails: 'Full coverage',
      contact: '800-345-6789'
    },
    medicalHistory: {
      pastIllnesses: ['Flu'],
      familyHistory: ['Thyroid Disease (mother)'],
      immunizations: ['Hepatitis B'],
      previousHospitalizations: ['2010 - Thyroidectomy']
    },
    lifestyle: {
      smokingStatus: 'Non-smoker',
      alcoholConsumption: 'Occasional',
      exerciseRoutine: 'Weekly',
      dietaryPreferences: 'Low-sodium'
    },
    regularCheckups: {
      primaryCarePhysician: 'Dr. Lee',
      nextCheckup: '2024-12-05',
      lastPhysicalExam: '2023-06-05',
      screeningTests: ['Thyroid Function Test (2023)']
    },
    detailedMedications: [
      {
        name: 'Levothyroxine',
        dosage: '75mcg',
        frequency: 'Once daily',
        prescribingPhysician: 'Dr. Lee',
        startDate: '2010-11-30',
        endDate: null
      }
    ],
    labResults: {
      bloodTests: ['2023-06-05', '2024-05-05'],
      imagingResults: [
        {
          date: '2023-06-05',
          description: 'Ultrasound of the thyroid.',
          imageUrl: xray09
        },
        {
          date: '2024-04-12',
          description: 'X-ray of the spine.',
          imageUrl: xray10
        },
        {
          date: '2023-10-23',
          description: 'X-ray of the knee.',
          imageUrl: xray11
        },
        {
          date: '2024-02-14',
          description: 'X-ray of the shoulder.',
          imageUrl: xray12
        }
      ],
      biopsyResults: [],
      otherDiagnostics: []
    },
    mentalHealth: {
      diagnoses: [],
      therapySessions: [],
      medications: [],
      psychiatristContact: null
    },
    emergencyContact: {
      name: 'Sam Johnson',
      relationship: 'Spouse',
      phone: '555-7890'
    },
    contact: {
      phone: '555-6789',
      email: 'alice.johnson@example.com',
      address: '789 Pine St, Anytown, USA'
    },
    avatar: ''
  },
  {
    id: '4',
    name: 'Bob Brown',
    age: 65,
    gender: 'Male',
    dob: '1959-02-17',
    ssn: '456-78-9012',
    lastVisit: '2024-06-12',
    status: 'Inactive',
    healthIssues: ['Arthritis'],
    operations: ['Hip Replacement (2018)'],
    medications: ['Ibuprofen'],
    allergies: ['Latex'],
    height: '6ft 0in',
    weight: '200 lbs',
    bloodType: 'AB+',
    insurance: {
      provider: 'Aetna',
      policyNumber: 'AE456789012',
      coverageDetails: 'Full coverage',
      contact: '800-456-7890'
    },
    medicalHistory: {
      pastIllnesses: ['Cold'],
      familyHistory: ['Arthritis (mother)'],
      immunizations: ['Pneumonia Vaccine'],
      previousHospitalizations: ['2018 - Hip Replacement']
    },
    lifestyle: {
      smokingStatus: 'Former smoker',
      alcoholConsumption: 'Moderate',
      exerciseRoutine: 'Monthly',
      dietaryPreferences: 'Low-fat'
    },
    regularCheckups: {
      primaryCarePhysician: 'Dr. Davis',
      nextCheckup: '2024-11-12',
      lastPhysicalExam: '2023-06-12',
      screeningTests: ['Bone Density Test (2022)']
    },
    detailedMedications: [
      {
        name: 'Ibuprofen',
        dosage: '200mg',
        frequency: 'As needed',
        prescribingPhysician: 'Dr. Davis',
        startDate: '2018-06-12',
        endDate: null
      }
    ],
    labResults: {
      bloodTests: ['2023-06-12'],
      imagingResults: [
        {
          date: '2023-06-12',
          description: 'X-ray of the hip.',
          imageUrl: xray13
        },
        {
          date: '2024-03-15',
          description: 'X-ray of the spine.',
          imageUrl: xray14
        },
        {
          date: '2023-09-10',
          description: 'X-ray of the shoulder.',
          imageUrl: xray15
        },
        {
          date: '2024-05-20',
          description: 'X-ray of the chest.',
          imageUrl: xray16
        }
      ],
      biopsyResults: [],
      otherDiagnostics: []
    },
    mentalHealth: {
      diagnoses: [],
      therapySessions: [],
      medications: [],
      psychiatristContact: null
    },
    emergencyContact: {
      name: 'Sue Brown',
      relationship: 'Spouse',
      phone: '555-8901'
    },
    contact: {
      phone: '555-7890',
      email: 'bob.brown@example.com',
      address: '321 Birch St, Anytown, USA'
    },
    avatar: ''
  },
  {
    id: '5',
    name: 'Carol White',
    age: 35,
    gender: 'Female',
    dob: '1989-07-21',
    ssn: '567-89-0123',
    lastVisit: '2024-06-03',
    status: 'Active',
    healthIssues: ['Hypertension'],
    operations: [],
    medications: ['Amlodipine'],
    allergies: ['None'],
    height: '5ft 4in',
    weight: '140 lbs',
    bloodType: 'O-',
    insurance: {
      provider: 'Blue Cross',
      policyNumber: 'BC567890123',
      coverageDetails: 'Full coverage',
      contact: '800-567-8901'
    },
    medicalHistory: {
      pastIllnesses: ['None'],
      familyHistory: ['Hypertension (father)'],
      immunizations: ['Flu Shot'],
      previousHospitalizations: []
    },
    lifestyle: {
      smokingStatus: 'Non-smoker',
      alcoholConsumption: 'Occasional',
      exerciseRoutine: 'Weekly',
      dietaryPreferences: 'None'
    },
    regularCheckups: {
      primaryCarePhysician: 'Dr. Wilson',
      nextCheckup: '2024-12-03',
      lastPhysicalExam: '2023-06-03',
      screeningTests: ['Blood Pressure Test (2023)']
    },
    detailedMedications: [
      {
        name: 'Amlodipine',
        dosage: '5mg',
        frequency: 'Once daily',
        prescribingPhysician: 'Dr. Wilson',
        startDate: '2018-07-21',
        endDate: null
      }
    ],
    labResults: {
      bloodTests: ['2023-06-03'],
      imagingResults: [
        {
          date: '2023-06-03',
          description: 'X-ray of the chest.',
          imageUrl: xray17
        },
        {
          date: '2024-01-18',
          description: 'X-ray of the abdomen.',
          imageUrl: xray18
        },
        {
          date: '2023-05-09',
          description: 'X-ray of the pelvis.',
          imageUrl: xray19
        },
        {
          date: '2024-02-25',
          description: 'X-ray of the knee.',
          imageUrl: xray20
        }
      ],
      biopsyResults: [],
      otherDiagnostics: []
    },
    mentalHealth: {
      diagnoses: [],
      therapySessions: [],
      medications: [],
      psychiatristContact: null
    },
    emergencyContact: {
      name: 'Tom White',
      relationship: 'Spouse',
      phone: '555-9012'
    },
    contact: {
      phone: '555-8901',
      email: 'carol.white@example.com',
      address: '654 Cedar St, Anytown, USA'
    },
    avatar: ''
  },
  {
    id: '6',
    name: 'David Wilson',
    age: 40,
    gender: 'Male',
    dob: '1984-03-05',
    ssn: '678-90-1234',
    lastVisit: '2024-06-08',
    status: 'Active',
    healthIssues: [],
    operations: ['Appendectomy (2012)'],
    medications: [],
    allergies: ['None'],
    height: '5ft 11in',
    weight: '175 lbs',
    bloodType: 'A+',
    insurance: {
      provider: 'Cigna',
      policyNumber: 'CG678901234',
      coverageDetails: 'Partial coverage',
      contact: '800-678-9012'
    },
    medicalHistory: {
      pastIllnesses: ['None'],
      familyHistory: [],
      immunizations: ['MMR'],
      previousHospitalizations: ['2012 - Appendectomy']
    },
    lifestyle: {
      smokingStatus: 'Non-smoker',
      alcoholConsumption: 'Social',
      exerciseRoutine: 'Weekly',
      dietaryPreferences: 'None'
    },
    regularCheckups: {
      primaryCarePhysician: 'Dr. Martinez',
      nextCheckup: '2024-12-08',
      lastPhysicalExam: '2023-06-08',
      screeningTests: ['None']
    },
    detailedMedications: [],
    labResults: {
      bloodTests: ['2023-06-08'],
      imagingResults: [
        {
          date: '2023-06-08',
          description: 'X-ray of the chest.',
          imageUrl: xray21
        },
        {
          date: '2024-01-20',
          description: 'X-ray of the abdomen.',
          imageUrl: xray22
        },
        {
          date: '2023-04-15',
          description: 'X-ray of the pelvis.',
          imageUrl: xray23
        },
        {
          date: '2024-03-30',
          description: 'X-ray of the spine.',
          imageUrl: xray24
        }
      ],
      biopsyResults: [],
      otherDiagnostics: []
    },
    mentalHealth: {
      diagnoses: [],
      therapySessions: [],
      medications: [],
      psychiatristContact: null
    },
    emergencyContact: {
      name: 'Linda Wilson',
      relationship: 'Spouse',
      phone: '555-0123'
    },
    contact: {
      phone: '555-9012',
      email: 'david.wilson@example.com',
      address: '987 Spruce St, Anytown, USA'
    },
    avatar: ''
  },
  {
    id: '7',
    name: 'Emma Taylor',
    age: 55,
    gender: 'Female',
    dob: '1969-12-01',
    ssn: '789-01-2345',
    lastVisit: '2024-06-07',
    status: 'Inactive',
    healthIssues: ['Osteoporosis'],
    operations: [],
    medications: ['Calcium', 'Vitamin D'],
    allergies: ['None'],
    height: '5ft 7in',
    weight: '160 lbs',
    bloodType: 'B-',
    insurance: {
      provider: 'Medicare',
      policyNumber: 'MC789012345',
      coverageDetails: 'Full coverage',
      contact: '800-789-0123'
    },
    medicalHistory: {
      pastIllnesses: ['Flu'],
      familyHistory: ['Osteoporosis (mother)'],
      immunizations: ['Shingles Vaccine'],
      previousHospitalizations: []
    },
    lifestyle: {
      smokingStatus: 'Non-smoker',
      alcoholConsumption: 'Rarely',
      exerciseRoutine: 'Daily',
      dietaryPreferences: 'High-calcium'
    },
    regularCheckups: {
      primaryCarePhysician: 'Dr. Brown',
      nextCheckup: '2024-12-07',
      lastPhysicalExam: '2023-06-07',
      screeningTests: ['Bone Density Test (2023)']
    },
    detailedMedications: [
      {
        name: 'Calcium',
        dosage: '500mg',
        frequency: 'Twice daily',
        prescribingPhysician: 'Dr. Brown',
        startDate: '2019-12-01',
        endDate: null
      },
      {
        name: 'Vitamin D',
        dosage: '2000 IU',
        frequency: 'Once daily',
        prescribingPhysician: 'Dr. Brown',
        startDate: '2019-12-01',
        endDate: null
      }
    ],
    labResults: {
      bloodTests: ['2023-06-07'],
      imagingResults: [
        {
          date: '2023-06-07',
          description: 'DEXA Scan',
          imageUrl: xray25
        },
        {
          date: '2024-03-08',
          description: 'X-ray of the hand.',
          imageUrl: xray26
        },
        {
          date: '2023-12-19',
          description: 'X-ray of the hip.',
          imageUrl: xray27
        },
        {
          date: '2024-04-25',
          description: 'X-ray of the spine.',
          imageUrl: xray28
        }
      ],
      biopsyResults: [],
      otherDiagnostics: []
    },
    mentalHealth: {
      diagnoses: [],
      therapySessions: [],
      medications: [],
      psychiatristContact: null
    },
    emergencyContact: {
      name: 'Henry Taylor',
      relationship: 'Spouse',
      phone: '555-1234'
    },
    contact: {
      phone: '555-0123',
      email: 'emma.taylor@example.com',
      address: '321 Maple St, Anytown, USA'
    },
    avatar: ''
  },
  {
    id: '8',
    name: 'Frank Thomas',
    age: 60,
    gender: 'Male',
    dob: '1964-05-19',
    ssn: '890-12-3456',
    lastVisit: '2024-06-02',
    status: 'Active',
    healthIssues: ['COPD'],
    operations: [],
    medications: ['Tiotropium'],
    allergies: ['None'],
    height: '5ft 10in',
    weight: '190 lbs',
    bloodType: 'O+',
    insurance: {
      provider: 'United Health',
      policyNumber: 'UH890123456',
      coverageDetails: 'Partial coverage',
      contact: '800-890-1234'
    },
    medicalHistory: {
      pastIllnesses: ['Bronchitis'],
      familyHistory: ['COPD (father)'],
      immunizations: ['Pneumonia Vaccine'],
      previousHospitalizations: []
    },
    lifestyle: {
      smokingStatus: 'Former smoker',
      alcoholConsumption: 'Occasional',
      exerciseRoutine: 'Monthly',
      dietaryPreferences: 'Low-sodium'
    },
    regularCheckups: {
      primaryCarePhysician: 'Dr. Green',
      nextCheckup: '2024-11-02',
      lastPhysicalExam: '2023-06-02',
      screeningTests: ['Lung Function Test (2023)']
    },
    detailedMedications: [
      {
        name: 'Tiotropium',
        dosage: '18mcg',
        frequency: 'Once daily',
        prescribingPhysician: 'Dr. Green',
        startDate: '2020-05-19',
        endDate: null
      }
    ],
    labResults: {
      bloodTests: ['2023-06-02'],
      imagingResults: [
        {
          date: '2023-06-02',
          description: 'Chest X-ray.',
          imageUrl: xray29
        },
        {
          date: '2024-01-18',
          description: 'X-ray of the lungs.',
          imageUrl: xray30
        },
        {
          date: '2023-09-14',
          description: 'X-ray of the spine.',
          imageUrl: xray31
        },
        {
          date: '2024-05-25',
          description: 'X-ray of the pelvis.',
          imageUrl: xray32
        }
      ],
      biopsyResults: [],
      otherDiagnostics: []
    },
    mentalHealth: {
      diagnoses: [],
      therapySessions: [],
      medications: [],
      psychiatristContact: null
    },
    emergencyContact: {
      name: 'Helen Thomas',
      relationship: 'Spouse',
      phone: '555-2345'
    },
    contact: {
      phone: '555-1234',
      email: 'frank.thomas@example.com',
      address: '654 Walnut St, Anytown, USA'
    },
    avatar: ''
  },
  {
    id: '9',
    name: 'Grace Lee',
    age: 25,
    gender: 'Female',
    dob: '1999-09-09',
    ssn: '901-23-4567',
    lastVisit: '2024-06-04',
    status: 'Inactive',
    healthIssues: ['Anemia'],
    operations: [],
    medications: ['Iron Supplements'],
    allergies: ['None'],
    height: '5ft 3in',
    weight: '115 lbs',
    bloodType: 'A-',
    insurance: {
      provider: 'HealthNet',
      policyNumber: 'HN901234567',
      coverageDetails: 'Full coverage',
      contact: '800-901-2345'
    },
    medicalHistory: {
      pastIllnesses: ['None'],
      familyHistory: ['Anemia (mother)'],
      immunizations: ['HPV Vaccine'],
      previousHospitalizations: []
    },
    lifestyle: {
      smokingStatus: 'Non-smoker',
      alcoholConsumption: 'Never',
      exerciseRoutine: 'Daily',
      dietaryPreferences: 'None'
    },
    regularCheckups: {
      primaryCarePhysician: 'Dr. Taylor',
      nextCheckup: '2024-12-04',
      lastPhysicalExam: '2023-06-04',
      screeningTests: ['Blood Test (2023)']
    },
    detailedMedications: [
      {
        name: 'Iron Supplements',
        dosage: '325mg',
        frequency: 'Once daily',
        prescribingPhysician: 'Dr. Taylor',
        startDate: '2020-09-09',
        endDate: null
      }
    ],
    labResults: {
      bloodTests: ['2023-06-04'],
      imagingResults: [
        {
          date: '2023-06-04',
          description: 'X-ray of the hand.',
          imageUrl: xray33
        },
        {
          date: '2024-02-19',
          description: 'X-ray of the chest.',
          imageUrl: xray34
        },
        {
          date: '2023-08-10',
          description: 'X-ray of the abdomen.',
          imageUrl: xray35
        },
        {
          date: '2024-03-25',
          description: 'X-ray of the spine.',
          imageUrl: xray36
        }
      ],
      biopsyResults: [],
      otherDiagnostics: []
    },
    mentalHealth: {
      diagnoses: [],
      therapySessions: [],
      medications: [],
      psychiatristContact: null
    },
    emergencyContact: {
      name: 'Jane Lee',
      relationship: 'Mother',
      phone: '555-3456'
    },
    contact: {
      phone: '555-2345',
      email: 'grace.lee@example.com',
      address: '987 Willow St, Anytown, USA'
    },
    avatar: ''
  },
  {
    id: '10',
    name: 'Henry Walker',
    age: 70,
    gender: 'Male',
    dob: '1954-11-15',
    ssn: '012-34-5678',
    lastVisit: '2024-06-01',
    status: 'Active',
    healthIssues: ['Heart Disease'],
    operations: ['Bypass Surgery (2010)'],
    medications: ['Aspirin', 'Atorvastatin'],
    allergies: ['None'],
    height: '5ft 9in',
    weight: '185 lbs',
    bloodType: 'B+',
    insurance: {
      provider: 'Medicare',
      policyNumber: 'MC012345678',
      coverageDetails: 'Full coverage',
      contact: '800-012-3456'
    },
    medicalHistory: {
      pastIllnesses: ['Flu'],
      familyHistory: ['Heart Disease (father)'],
      immunizations: ['Pneumonia Vaccine'],
      previousHospitalizations: ['2010 - Bypass Surgery']
    },
    lifestyle: {
      smokingStatus: 'Former smoker',
      alcoholConsumption: 'Occasional',
      exerciseRoutine: 'Weekly',
      dietaryPreferences: 'Low-cholesterol'
    },
    regularCheckups: {
      primaryCarePhysician: 'Dr. Adams',
      nextCheckup: '2024-12-01',
      lastPhysicalExam: '2023-06-01',
      screeningTests: ['Cholesterol Test (2023)']
    },
    detailedMedications: [
      {
        name: 'Aspirin',
        dosage: '81mg',
        frequency: 'Once daily',
        prescribingPhysician: 'Dr. Adams',
        startDate: '2010-11-15',
        endDate: null
      },
      {
        name: 'Atorvastatin',
        dosage: '20mg',
        frequency: 'Once daily',
        prescribingPhysician: 'Dr. Adams',
        startDate: '2010-11-15',
        endDate: null
      }
    ],
    labResults: {
      bloodTests: ['2023-06-01'],
      imagingResults: [
        {
          date: '2023-06-01',
          description: 'ECG',
          imageUrl: xray37
        },
        {
          date: '2024-03-18',
          description: 'X-ray of the chest.',
          imageUrl: xray38
        },
        {
          date: '2023-10-23',
          description: 'X-ray of the spine.',
          imageUrl: xray39
        },
        {
          date: '2024-01-25',
          description: 'X-ray of the head.',
          imageUrl: xray40
        }
      ],
      biopsyResults: [],
      otherDiagnostics: []
    },
    mentalHealth: {
      diagnoses: [],
      therapySessions: [],
      medications: [],
      psychiatristContact: null
    },
    emergencyContact: {
      name: 'Mary Walker',
      relationship: 'Spouse',
      phone: '555-4567'
    },
    contact: {
      phone: '555-3456',
      email: 'henry.walker@example.com',
      address: '321 Elm St, Anytown, USA'
    },
    avatar: ''
  },
  {
    id: '11',
    name: 'Ivy Hall',
    age: 32,
    gender: 'Female',
    dob: '1992-01-23',
    ssn: '123-45-6789',
    lastVisit: '2024-06-14',
    status: 'Inactive',
    healthIssues: ['Migraines'],
    operations: [],
    medications: ['Sumatriptan'],
    allergies: ['None'],
    height: '5ft 4in',
    weight: '135 lbs',
    bloodType: 'AB-',
    insurance: {
      provider: 'HealthNet',
      policyNumber: 'HN123456789',
      coverageDetails: 'Partial coverage',
      contact: '800-123-4567'
    },
    medicalHistory: {
      pastIllnesses: ['None'],
      familyHistory: ['Migraines (mother)'],
      immunizations: ['Flu Shot'],
      previousHospitalizations: []
    },
    lifestyle: {
      smokingStatus: 'Non-smoker',
      alcoholConsumption: 'Social',
      exerciseRoutine: 'Weekly',
      dietaryPreferences: 'None'
    },
    regularCheckups: {
      primaryCarePhysician: 'Dr. Harris',
      nextCheckup: '2024-12-14',
      lastPhysicalExam: '2023-06-14',
      screeningTests: ['None']
    },
    detailedMedications: [
      {
        name: 'Sumatriptan',
        dosage: '50mg',
        frequency: 'As needed',
        prescribingPhysician: 'Dr. Harris',
        startDate: '2020-01-23',
        endDate: null
      }
    ],
    labResults: {
      bloodTests: ['2023-06-14'],
      imagingResults: [
        {
          date: '2023-06-14',
          description: 'X-ray of the head.',
          imageUrl: xray41
        },
        {
          date: '2024-02-10',
          description: 'X-ray of the neck.',
          imageUrl: xray42
        },
        {
          date: '2023-08-19',
          description: 'X-ray of the chest.',
          imageUrl: xray43
        },
        {
          date: '2024-01-15',
          description: 'X-ray of the abdomen.',
          imageUrl: xray44
        }
      ],
      biopsyResults: [],
      otherDiagnostics: []
    },
    mentalHealth: {
      diagnoses: [],
      therapySessions: [],
      medications: [],
      psychiatristContact: null
    },
    emergencyContact: {
      name: 'Mark Hall',
      relationship: 'Husband',
      phone: '555-5678'
    },
    contact: {
      phone: '555-4567',
      email: 'ivy.hall@example.com',
      address: '654 Pine St, Anytown, USA'
    },
    avatar: ''
  },
  {
    id: '12',
    name: 'Jack Allen',
    age: 38,
    gender: 'Male',
    dob: '1986-02-12',
    ssn: '234-56-7890',
    lastVisit: '2024-06-11',
    status: 'Active',
    healthIssues: [],
    operations: [],
    medications: [],
    allergies: ['None'],
    height: '6ft 1in',
    weight: '190 lbs',
    bloodType: 'O+',
    insurance: {
      provider: 'Cigna',
      policyNumber: 'CG234567890',
      coverageDetails: 'Full coverage',
      contact: '800-234-5678'
    },
    medicalHistory: {
      pastIllnesses: ['None'],
      familyHistory: [],
      immunizations: ['MMR'],
      previousHospitalizations: []
    },
    lifestyle: {
      smokingStatus: 'Non-smoker',
      alcoholConsumption: 'Occasional',
      exerciseRoutine: 'Weekly',
      dietaryPreferences: 'None'
    },
    regularCheckups: {
      primaryCarePhysician: 'Dr. Clark',
      nextCheckup: '2024-12-11',
      lastPhysicalExam: '2023-06-11',
      screeningTests: ['None']
    },
    detailedMedications: [],
    labResults: {
      bloodTests: ['2023-06-11'],
      imagingResults: [
        {
          date: '2023-06-11',
          description: 'X-ray of the chest.',
          imageUrl: xray45
        },
        {
          date: '2024-03-05',
          description: 'X-ray of the abdomen.',
          imageUrl: xray46
        },
        {
          date: '2023-09-12',
          description: 'X-ray of the pelvis.',
          imageUrl: xray47
        },
        {
          date: '2024-01-20',
          description: 'X-ray of the leg.',
          imageUrl: xray48
        }
      ],
      biopsyResults: [],
      otherDiagnostics: []
    },
    mentalHealth: {
      diagnoses: [],
      therapySessions: [],
      medications: [],
      psychiatristContact: null
    },
    emergencyContact: {
      name: 'Jill Allen',
      relationship: 'Wife',
      phone: '555-6789'
    },
    contact: {
      phone: '555-5678',
      email: 'jack.allen@example.com',
      address: '789 Oak St, Anytown, USA'
    },
    avatar: ''
  },
  {
    id: '13',
    name: 'Karen Young',
    age: 42,
    gender: 'Female',
    dob: '1982-10-20',
    ssn: '345-67-8901',
    lastVisit: '2024-06-13',
    status: 'Active',
    healthIssues: ['Depression'],
    operations: [],
    medications: ['Sertraline'],
    allergies: ['None'],
    height: '5ft 5in',
    weight: '145 lbs',
    bloodType: 'A-',
    insurance: {
      provider: 'HealthFirst',
      policyNumber: 'HF345678901',
      coverageDetails: 'Partial coverage',
      contact: '800-345-6789'
    },
    medicalHistory: {
      pastIllnesses: ['None'],
      familyHistory: ['Depression (mother)'],
      immunizations: ['Flu Shot'],
      previousHospitalizations: []
    },
    lifestyle: {
      smokingStatus: 'Non-smoker',
      alcoholConsumption: 'Rarely',
      exerciseRoutine: 'Daily',
      dietaryPreferences: 'None'
    },
    regularCheckups: {
      primaryCarePhysician: 'Dr. Turner',
      nextCheckup: '2024-12-13',
      lastPhysicalExam: '2023-06-13',
      screeningTests: ['None']
    },
    detailedMedications: [
      {
        name: 'Sertraline',
        dosage: '50mg',
        frequency: 'Once daily',
        prescribingPhysician: 'Dr. Turner',
        startDate: '2018-10-20',
        endDate: null
      }
    ],
    labResults: {
      bloodTests: ['2023-06-13'],
      imagingResults: [
        {
          date: '2023-06-13',
          description: 'X-ray of the chest.',
          imageUrl: xray49
        },
        {
          date: '2024-01-17',
          description: 'X-ray of the abdomen.',
          imageUrl: xray50
        },
        {
          date: '2023-08-13',
          description: 'X-ray of the spine.',
          imageUrl: xray51
        },
        {
          date: '2024-03-29',
          description: 'X-ray of the head.',
          imageUrl: xray52
        }
      ],
      biopsyResults: [],
      otherDiagnostics: []
    },
    mentalHealth: {
      diagnoses: ['Depression'],
      therapySessions: ['2023-10-20', '2024-01-20'],
      medications: ['Sertraline'],
      psychiatristContact: 'Dr. Turner - 555-3456'
    },
    emergencyContact: {
      name: 'Ken Young',
      relationship: 'Husband',
      phone: '555-7890'
    },
    contact: {
      phone: '555-6789',
      email: 'karen.young@example.com',
      address: '321 Maple St, Anytown, USA'
    },
    avatar: ''
  },
  {
    id: '14',
    name: 'Leo King',
    age: 48,
    gender: 'Male',
    dob: '1976-07-13',
    ssn: '456-78-9012',
    lastVisit: '2024-06-09',
    status: 'Inactive',
    healthIssues: ['Chronic Back Pain'],
    operations: ['Back Surgery (2015)'],
    medications: ['Ibuprofen'],
    allergies: ['None'],
    height: '5ft 8in',
    weight: '180 lbs',
    bloodType: 'B+',
    insurance: {
      provider: 'Aetna',
      policyNumber: 'AE456789012',
      coverageDetails: 'Full coverage',
      contact: '800-456-7890'
    },
    medicalHistory: {
      pastIllnesses: ['None'],
      familyHistory: ['Back Pain (father)'],
      immunizations: ['Tetanus'],
      previousHospitalizations: ['2015 - Back Surgery']
    },
    lifestyle: {
      smokingStatus: 'Non-smoker',
      alcoholConsumption: 'Occasional',
      exerciseRoutine: 'Weekly',
      dietaryPreferences: 'None'
    },
    regularCheckups: {
      primaryCarePhysician: 'Dr. Carter',
      nextCheckup: '2024-12-09',
      lastPhysicalExam: '2023-06-09',
      screeningTests: ['None']
    },
    detailedMedications: [
      {
        name: 'Ibuprofen',
        dosage: '200mg',
        frequency: 'As needed',
        prescribingPhysician: 'Dr. Carter',
        startDate: '2015-07-13',
        endDate: null
      }
    ],
    labResults: {
      bloodTests: ['2023-06-09'],
      imagingResults: [
        {
          date: '2023-06-09',
          description: 'MRI of the back.',
          imageUrl: xray53
        },
        {
          date: '2024-02-10',
          description: 'X-ray of the spine.',
          imageUrl: xray54
        },
        {
          date: '2023-09-15',
          description: 'X-ray of the shoulder.',
          imageUrl: xray55
        },
        {
          date: '2024-04-30',
          description: 'X-ray of the chest.',
          imageUrl: xray56
        }
      ],
      biopsyResults: [],
      otherDiagnostics: []
    },
    mentalHealth: {
      diagnoses: [],
      therapySessions: [],
      medications: [],
      psychiatristContact: null
    },
    emergencyContact: {
      name: 'Linda King',
      relationship: 'Wife',
      phone: '555-8901'
    },
    contact: {
      phone: '555-7890',
      email: 'leo.king@example.com',
      address: '987 Birch St, Anytown, USA'
    },
    avatar: ''
  },
  {
    id: '15',
    name: 'Mona Wright',
    age: 29,
    gender: 'Female',
    dob: '1995-04-05',
    ssn: '567-89-0123',
    lastVisit: '2024-06-06',
    status: 'Active',
    healthIssues: [],
    operations: [],
    medications: [],
    allergies: ['None'],
    height: '5ft 6in',
    weight: '140 lbs',
    bloodType: 'O-',
    insurance: {
      provider: 'Blue Cross',
      policyNumber: 'BC567890123',
      coverageDetails: 'Full coverage',
      contact: '800-567-8901'
    },
    medicalHistory: {
      pastIllnesses: ['None'],
      familyHistory: [],
      immunizations: ['MMR'],
      previousHospitalizations: []
    },
    lifestyle: {
      smokingStatus: 'Non-smoker',
      alcoholConsumption: 'Occasional',
      exerciseRoutine: 'Daily',
      dietaryPreferences: 'None'
    },
    regularCheckups: {
      primaryCarePhysician: 'Dr. White',
      nextCheckup: '2024-12-06',
      lastPhysicalExam: '2023-06-06',
      screeningTests: ['None']
    },
    detailedMedications: [],
    labResults: {
      bloodTests: ['2023-06-06'],
      imagingResults: [
        {
          date: '2023-06-06',
          description: 'X-ray of the chest.',
          imageUrl: xray57
        },
        {
          date: '2024-01-14',
          description: 'X-ray of the abdomen.',
          imageUrl: xray58
        },
        {
          date: '2023-08-12',
          description: 'X-ray of the pelvis.',
          imageUrl: xray59
        },
        {
          date: '2024-03-20',
          description: 'X-ray of the leg.',
          imageUrl: xray60
        }
      ],
      biopsyResults: [],
      otherDiagnostics: []
    },
    mentalHealth: {
      diagnoses: [],
      therapySessions: [],
      medications: [],
      psychiatristContact: null
    },
    emergencyContact: {
      name: 'Mike Wright',
      relationship: 'Husband',
      phone: '555-9012'
    },
    contact: {
      phone: '555-8901',
      email: 'mona.wright@example.com',
      address: '654 Cedar St, Anytown, USA'
    },
    avatar: ''
  }
];
