import React from 'react';
import { Stepper, Step, StepLabel, StepConnector, styled } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

const CustomConnector = styled(StepConnector)({
  '& .MuiStepConnector-line': {
    height: 3,
    border: 0,
    backgroundImage: 'linear-gradient(to right, #FF6347, #FF6347)',
    borderRadius: 1,
  },
});

const steps = [
  { label: 'Account Creation', icon: <AccountCircleIcon /> },
  { label: 'Organization Info', icon: <BusinessIcon /> },
  { label: 'Pricing Selection', icon: <MonetizationOnIcon /> },
  { label: 'Integration Setup', icon: <IntegrationInstructionsIcon /> },
];

const ProgressStepper = ({ activeStep }) => (
  <Stepper
    activeStep={activeStep}
    alternativeLabel
    connector={<CustomConnector />}
    sx={{ padding: '24px 0', bgcolor: 'transparent' }}
  >
    {steps.map((step, index) => (
      <Step key={step.label}>
        <StepLabel icon={step.icon}>
          <span style={{ fontSize: '14px', fontWeight: activeStep === index ? 'bold' : 'normal' }}>
            {step.label}
          </span>
        </StepLabel>
      </Step>
    ))}
  </Stepper>
);

export default ProgressStepper;
