
import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Paper, Divider } from '@mui/material';
import PatientTab from '../../../components/patientTab';

const Lifestyle = ({ patient }) => {
  const lifestyle = patient.lifestyle || {};

  const renderListItem = (title, value) => (
    <Box sx={{ mb: 2 }}>
      <Typography variant="subtitle1" gutterBottom>{title}</Typography>
      <List dense>
        <ListItem>
          <ListItemText primary={value} />
        </ListItem>
      </List>
      <Divider />
    </Box>
  );

  return (
    <PatientTab label="Lifestyle">
      <Paper sx={{ p: 2 }}>
        {renderListItem("Smoking Status", lifestyle.smokingStatus)}
        {renderListItem("Alcohol Consumption", lifestyle.alcoholConsumption)}
        {renderListItem("Exercise Routine", lifestyle.exerciseRoutine)}
        {renderListItem("Dietary Preferences", lifestyle.dietaryPreferences)}
      </Paper>
    </PatientTab>
  );
};

export default Lifestyle;
