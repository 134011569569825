import React from 'react';
import { Box, TextField, Typography, FormControl, MenuItem, Select } from '@mui/material';

const IntegrationSetup = () => (
  <Box>
    <Typography variant="h6" gutterBottom>
      Integration Setup
    </Typography>
    <FormControl fullWidth margin="normal">
      <Select defaultValue="" displayEmpty>
        <MenuItem value="" disabled>
          Select EHR/EMR System
        </MenuItem>
        <MenuItem value="Epic">Epic</MenuItem>
        <MenuItem value="Cerner">Cerner</MenuItem>
        <MenuItem value="Meditech">Meditech</MenuItem>
        <MenuItem value="Allscripts">Allscripts</MenuItem>
        {/* Add other EHR/EMR options as needed */}
      </Select>
    </FormControl>
    <TextField label="API Key" fullWidth margin="normal" />
    <TextField label="API Secret" type="password" fullWidth margin="normal" />
  </Box>
);

export default IntegrationSetup;
