import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import MetricBox from '../../components/metricBox';
import HeaderBox from '../../components/headerBox';
import RecentActivity from '../../components/reacentActivity';
import { activitiesData } from '../../data/activitiesData';
import { patientsData } from '../../data/patientData';

// Mock data for demo mode
const demoUser = {
    firstName: 'Demo',
    lastName: 'User',
    email: 'demo@kuduu.io',
};

const demoActivities = activitiesData; 
const demoPatients = patientsData;   

const Dashboard = () => {
    const displayUser = demoUser;
    const displayActivities = demoActivities;
    const displayPatients = demoPatients;

    return (
        <Container>
            <Box
                sx={{
                    width: '100%',
                    marginTop: '25px',
                }}
            >
                <Box
                    sx={{
                        padding: '16px',
                        borderRadius: '8px',
                        marginBottom: '24px',
                    }}
                >
                    <HeaderBox
                        type="greeting"
                        title="Welcome"
                        user={displayUser.firstName}
                        subtext="Welcome to your dashboard. Here's a quick overview of your recent activities, key metrics, and notifications to keep you updated."
                    />
                    <MetricBox />
                </Box>
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        marginBottom: '8px',
                    }}
                >
                    Recent Activity
                </Typography>
                <RecentActivity
                    activitiesData={displayActivities}
                    patientsData={displayPatients}
                />
            </Box>
        </Container>
    );
};

export default Dashboard;
