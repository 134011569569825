import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Paper, Divider } from '@mui/material';
import PatientTab from '../../../components/patientTab';

const MedicalHistory = ({ patient }) => {
  const medicalHistory = patient.medicalHistory || {};

  const renderList = (title, items) => (
    <Box sx={{ mb: 2 }}>
      <Typography variant="subtitle1" gutterBottom>{title}</Typography>
      <List dense>
        {items.map((item, index) => (
          <ListItem key={index}>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <PatientTab label="Medical History">
      <Paper sx={{ p: 2 }}>
        {renderList("Past Illnesses", medicalHistory.pastIllnesses)}
        {renderList("Family History", medicalHistory.familyHistory)}
        {renderList("Immunizations", medicalHistory.immunizations)}
        {renderList("Previous Hospitalizations", medicalHistory.previousHospitalizations)}
      </Paper>
    </PatientTab>
  );
};

export default MedicalHistory;