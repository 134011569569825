import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const MissionSection = () => {
    return (
        <Box sx={{ py: 8, bgcolor: '#1E3A8A' }}>
            <Container>
                <Typography variant="h3" align="center" gutterBottom sx={{ color: 'white', fontWeight: 'bold', mb: 2 }}>
                    Our Mission
                    <AutoAwesomeIcon sx={{ color: 'white', fontSize: '2.5rem', ml: 1 }} />
                </Typography>
                <Typography align="center" sx={{ color: 'white', mb: 2 }} variant="h5">
                    "To revolutionize patient care and outcomes through AI-powered solutions."
                </Typography>
            </Container>
        </Box>
    );
};

export default MissionSection;
