import React, { useState } from 'react';
import Header from '../../components/header';
import DrawerComponent from '../../components/drawer';
import HeroSection from '../../components/heroSection';
import MissionSection from '../../components/missionSection';
import FeaturesSection from '../../components/featuresSection';
import CallToActionSection from '../../components/callToAction';
import AboutUsSection from '../../components/aboutUsSection';
import Footer from '../../components/footer';
import { Box } from '@mui/material';

const LandingPage = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const handleScroll = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setDrawerOpen(false); // Close drawer on mobile after scroll
        }
    };


    return (
        <Box sx={{ flexGrow: 1, minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Header toggleDrawer={toggleDrawer} handleScroll={handleScroll}  />
            <DrawerComponent drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} handleScroll={handleScroll} />
            <Box component="main" sx={{ flex: 1, mt: 5 }}>
                <HeroSection />
                <MissionSection />
                <FeaturesSection />
                <CallToActionSection />
                <AboutUsSection />
            </Box>
            <Footer />
        </Box>
    );
};

export default LandingPage;
