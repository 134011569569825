import React, { useState } from 'react';
import { Box, Typography, FormControlLabel, Checkbox, Button, Divider } from '@mui/material';

const FeatureSelection = ({ onNext }) => {
  const [features, setFeatures] = useState({
    fullSuite: false,
    chatAssistant: false,
    healthAnalysis: false,
    predictiveInsights: false,
    xrayRecognition: false,
    ehrIntegration: false,
    appointmentScheduling: false,
    labResultsProcessing: false,
    billingAutomation: false,
    followUpReminders: false,
    analyticsDashboard: false,
    diagnosisSuggestions: false,
    customWorkflows: false,
    iotIntegration: false,
  });

  const handleFeatureChange = (feature) => {
    setFeatures((prevFeatures) => ({
      ...prevFeatures,
      [feature]: !prevFeatures[feature],
    }));
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h5" gutterBottom>
        Select Your Features
      </Typography>
      <Divider sx={{ my: 2 }} />
      <FormControlLabel
        control={<Checkbox checked={features.fullSuite} onChange={() => handleFeatureChange('fullSuite')} />}
        label="Full Suite (All Features)"
      />
      <Divider sx={{ my: 2 }} />
      <Typography variant="h6">AI-Powered Tools</Typography>
      <FormControlLabel
        control={<Checkbox checked={features.chatAssistant} onChange={() => handleFeatureChange('chatAssistant')} />}
        label="AI-Powered Chat Assistant"
      />
      <FormControlLabel
        control={<Checkbox checked={features.healthAnalysis} onChange={() => handleFeatureChange('healthAnalysis')} />}
        label="AI-Powered Health Analysis"
      />
      <FormControlLabel
        control={<Checkbox checked={features.predictiveInsights} onChange={() => handleFeatureChange('predictiveInsights')} />}
        label="Predictive Health Insights/Analysis"
      />
      <FormControlLabel
        control={<Checkbox checked={features.xrayRecognition} onChange={() => handleFeatureChange('xrayRecognition')} />}
        label="X-ray Process Recognition"
      />
      
      <Typography variant="h6" sx={{ mt: 2 }}>Workflow Automation</Typography>
      <FormControlLabel
        control={<Checkbox checked={features.appointmentScheduling} onChange={() => handleFeatureChange('appointmentScheduling')} />}
        label="Automated Appointment Scheduling"
      />
      <FormControlLabel
        control={<Checkbox checked={features.labResultsProcessing} onChange={() => handleFeatureChange('labResultsProcessing')} />}
        label="Lab Results Processing"
      />
      <FormControlLabel
        control={<Checkbox checked={features.billingAutomation} onChange={() => handleFeatureChange('billingAutomation')} />}
        label="Billing and Invoicing Automation"
      />

    </Box>
  );
};

export default FeatureSelection;
