import xray01 from '../assets/xrays/01.png';
import xray02 from '../assets/xrays/02.png';
import xray03 from '../assets/xrays/03.png';
import xray04 from '../assets/xrays/04.png';
import xray05 from '../assets/xrays/05.png';
import xray06 from '../assets/xrays/06.png';
import xray07 from '../assets/xrays/07.png';
import xray08 from '../assets/xrays/08.png';
import xray09 from '../assets/xrays/09.png';
import xray10 from '../assets/xrays/10.png';
import xray11 from '../assets/xrays/11.png';
import xray12 from '../assets/xrays/12.png';
import xray13 from '../assets/xrays/13.png';
import xray14 from '../assets/xrays/14.png';
import xray15 from '../assets/xrays/15.png';
import xray16 from '../assets/xrays/16.png';
import xray17 from '../assets/xrays/17.png';
import xray18 from '../assets/xrays/18.png';
import xray19 from '../assets/xrays/19.png';
import xray20 from '../assets/xrays/20.png';
import xray21 from '../assets/xrays/21.png';
import xray22 from '../assets/xrays/22.png';
import xray23 from '../assets/xrays/23.png';
import xray24 from '../assets/xrays/24.png';
import xray25 from '../assets/xrays/25.png';
import xray26 from '../assets/xrays/26.png';
import xray27 from '../assets/xrays/27.png';
import xray28 from '../assets/xrays/28.png';
import xray29 from '../assets/xrays/29.png';
import xray30 from '../assets/xrays/30.png';
import xray31 from '../assets/xrays/31.png';
import xray32 from '../assets/xrays/32.png';
import xray33 from '../assets/xrays/33.png';
import xray34 from '../assets/xrays/34.png';
import xray35 from '../assets/xrays/35.png';
import xray36 from '../assets/xrays/36.png';
import xray37 from '../assets/xrays/37.png';
import xray38 from '../assets/xrays/38.png';
import xray39 from '../assets/xrays/39.png';
import xray40 from '../assets/xrays/40.png';
import xray41 from '../assets/xrays/41.png';
import xray42 from '../assets/xrays/42.png';
import xray43 from '../assets/xrays/43.png';
import xray44 from '../assets/xrays/44.png';
import xray45 from '../assets/xrays/45.png';
import xray46 from '../assets/xrays/46.png';
import xray47 from '../assets/xrays/47.png';
import xray48 from '../assets/xrays/48.png';
import xray49 from '../assets/xrays/49.png';
import xray50 from '../assets/xrays/50.png';
import xray51 from '../assets/xrays/51.png';
import xray52 from '../assets/xrays/52.png';
import xray53 from '../assets/xrays/53.png';
import xray54 from '../assets/xrays/54.png';
import xray55 from '../assets/xrays/55.png';
import xray56 from '../assets/xrays/56.png';
import xray57 from '../assets/xrays/57.png';
import xray58 from '../assets/xrays/58.png';
import xray59 from '../assets/xrays/59.png';
import xray60 from '../assets/xrays/60.png';
import xray61 from '../assets/xrays/61.png';
import xray62 from '../assets/xrays/62.png';
import xray63 from '../assets/xrays/63.png';
import xray64 from '../assets/xrays/64.png';
import xray65 from '../assets/xrays/65.png';
import xray66 from '../assets/xrays/66.png';
import xray67 from '../assets/xrays/67.png';
import xray68 from '../assets/xrays/68.png';
import xray69 from '../assets/xrays/69.png';
import xray70 from '../assets/xrays/70.png';
import xray71 from '../assets/xrays/71.png';

export    
{    
    xray01,
    xray02,
    xray03,
    xray04,
    xray05,
    xray06,
    xray07,
    xray08,
    xray09,
    xray10,
    xray11,
    xray12,
    xray13,
    xray14,
    xray15,
    xray16,
    xray17,
    xray18,
    xray19,
    xray20,
    xray21,
    xray22,
    xray23,
    xray24,
    xray25,
    xray26,
    xray27,
    xray28,
    xray29,
    xray30,
    xray31,
    xray32,
    xray33,
    xray34,
    xray35,
    xray36,
    xray37,
    xray38,
    xray39,
    xray40,
    xray41,
    xray42,
    xray43,
    xray44,
    xray45,
    xray46,
    xray47,
    xray48,
    xray49,
    xray50,
    xray51,
    xray52,
    xray53,
    xray54,
    xray55,
    xray56,
    xray57,
    xray58,
    xray59,
    xray60,
    xray61,
    xray62,
    xray63,
    xray64,
    xray65,
    xray66,
    xray67,
    xray68,
    xray69,
    xray70,
    xray71,
};