import React, { useState, useEffect } from 'react';
import {
	Box,
	Typography,
	Button,
	Container,
	Grid,
	TextField,
	CircularProgress,
} from '@mui/material';
import Hero from '../assets/infograph.png';
import axios from 'axios';
import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

const HeroSection = ({ handleClickOpen }) => {
	const [email, setEmail] = useState('');
	const [errors, setErrors] = useState({ email: '' });
	const [message, setMessage] = useState('');
	const [messageColor, setMessageColor] = useState('#000');
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const captchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
	const emailEndpoint = process.env.REACT_APP_EMAIL_ENDPOINT;

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!emailRegex.test(email)) {
			setErrors({ email: 'Invalid email address.' });
			return;
		}

		setLoading(true);
		try {
			const token = await new Promise((resolve, reject) => {
				window.grecaptcha.enterprise
					.execute(captchaSiteKey, { action: 'submit' })
					.then(resolve)
					.catch(reject);
			});

			if (!token) {
				setErrors({ captcha: 'Please verify that you are not a robot.' });
				setLoading(false);
				return;
			}

			const response = await axios.post(
				`${emailEndpoint}/subscribe`,
				{ email, captcha: token },
				{ headers: { 'Content-Type': 'application/json' } }
			);

			setMessage(response.data);
			setMessageColor('#1E3A8A');
			setEmail('');
		} catch (error) {
			setMessage(
				error.response && error.response.status === 400
					? error.response.data
					: 'An error occurred. Please try again later.'
			);
			setMessageColor('#FF0000');
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const loadReCAPTCHA = () => {
			const script = document.createElement('script');
			script.src = `https://www.google.com/recaptcha/enterprise.js?render=${captchaSiteKey}`;
			script.async = true;
			script.defer = true;
			document.body.appendChild(script);
		};
		loadReCAPTCHA();
	}, [captchaSiteKey]);

	const handleExperienceClick = () => {
		if (!isMobile) {
			sessionStorage.removeItem('demoEntered');
			navigate('/demo/dashboard');
		}
	};

	return (
		<Box sx={{ py: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Container sx={{ maxWidth: 'xl', padding: '0 5%' }}>
				<Grid container spacing={4} alignItems="center">
					<Grid item xs={12} md={7} sx={{ textAlign: 'left' }}>
						<Typography variant="h2" gutterBottom>
							<Box
								component="span"
								sx={{
									background: 'linear-gradient(180deg, #1E3A8A 0%, #3B82F6 100%)',
									WebkitBackgroundClip: 'text',
									WebkitTextFillColor: 'transparent',
									display: 'inline',
									fontWeight: 'bold',
								}}
							>
								AI-Powered Healthcare
							</Box>{' '}
							for Smarter Diagnoses, Faster Decisions, Better Outcomes
						</Typography>
						<Typography variant="h6" gutterBottom>
							Kuduu simplifies health data analysis, helping you make informed
							decisions and deliver better patient care with AI-driven insights.
						</Typography>

						{/* Email Signup Section */}
						<Box
							component="form"
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								gap: 1,
								maxWidth: '600px',
								mx: 'auto',
								width: '100%',
								mt: 3,
							}}
							noValidate
							autoComplete="off"
							onSubmit={handleSubmit}
						>
							<TextField
								id="email-input"
								label="Email"
								variant="standard"
								value={email}
								error={!!errors.email}
								helperText={errors.email}
								onChange={(e) => setEmail(e.target.value)}
								sx={{
									width: { xs: '65%', sm: '70%' },
									height: '56px',
								}}
							/>
							<Button
								type="submit"
								variant="contained"
								sx={{
									width: { xs: '35%', sm: '30%' },
									textTransform: 'none',
									backgroundColor: '#1E3A8A',
								}}
								disabled={loading}
							>
								{loading ? (
									<CircularProgress size={20} sx={{ color: 'white' }} />
								) : (
									'Subscribe Now'
								)}
							</Button>
						</Box>
						{message && (
							<Typography color={messageColor} sx={{ textAlign: 'center', mt: 1 }}>
								{message}
							</Typography>
						)}

						{/* Buttons for Demo */}
						<Box
							sx={{
								display: { xs: 'flex', sm: 'flex' },
								flexDirection: { xs: 'column', sm: 'row' },
								alignItems: 'center',
								textAlign: 'center',
								mt: 2,
								ml: { sm: 2 },
							}}
						>
							<Button
								component={RouterLink}
								to="/signup"
								variant="contained"
								sx={{
									background: 'linear-gradient(180deg, #1E3A8A 0%, #3B82F6 100%)',
									width: { xs: '100%', sm: 'auto' },
									maxWidth: '250px',
									mb: { xs: 1, sm: 0 },
								}}
							>
								Get Started
							</Button>
							<Button
								variant="contained"
								sx={{
									mt: { xs: 1, sm: 0 },
									ml: { sm: 2 },
									backgroundColor: isMobile
										? '#D3D3D3'
										: 'linear-gradient(90deg, #3B82F6 0%, #1E3A8A 100%)',
									color: isMobile ? '#888' : 'white',
									width: { xs: '100%', sm: 'auto' },
									maxWidth: '250px',
									cursor: isMobile ? 'not-allowed' : 'pointer',
								}}
								onClick={handleExperienceClick}
								disabled={isMobile}
							>
								Experience
							</Button>
							{isMobile && (
								<Typography
									variant="caption"
									sx={{
										color: '#888',
										mt: 1,
										textAlign: 'center',
										fontSize: '0.8rem',
									}}
								>
									To demo our features, please visit this page on a desktop
									device.
								</Typography>
							)}
						</Box>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						sx={{
							display: 'flex',
							justifyContent: { xs: 'center', md: 'flex-end' },
							mb: 15,
						}}
					>
						<img
							src={Hero}
							alt="Hero"
							style={{
								maxWidth: '500px',
								height: 'auto',
								cursor: 'pointer',
							}}
							onClick={handleClickOpen}
						/>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default HeroSection;
