import React from 'react';
import { Box, Typography } from '@mui/material';

const HeaderBox = ({ type = 'title', title, subtext, user }) => {
	return (
		<Box
			sx={{
				padding: '16px',
				backgroundColor: 'white',
			}}
		>
			<Typography
				variant="h1"
				sx={{
					fontSize: '2rem',
					fontWeight: 'bold',
					marginBottom: '8px',
				}}
			>
				{title}
				{type === 'greeting' && (
					<Typography
						variant='h1'
						component="span"
						sx={{
							fontSize: '2rem',
							fontWeight: 'bold',
							marginBottom: '8px',
							color: '#3477F5',
						}}
					>
						&nbsp;{user}
					</Typography>
				)}
			</Typography>
			<Typography
				variant="body1"
				sx={{
					fontSize: '1rem',
					color: 'gray',
				}}
			>
				{subtext}
			</Typography>
		</Box>
	);
};

export default HeaderBox;
