import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';


const RecentActivity = ({activitiesData, patientsData}) => {
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Merge activitiesData and patientsData based on patient id
  const mergedData = activitiesData.map(activity => {
    const patient = patientsData.find(patient => patient.id === activity.id);
    return {
      ...activity,
      patientName: patient ? patient.name : 'Unknown'
    };
  });

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Patient Name</TableCell>
              <TableCell>Activity Name</TableCell>
              <TableCell>Details</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mergedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((activity) => (
              <TableRow key={activity.id}>
                <TableCell>{activity.patientName}</TableCell>
                <TableCell>{activity.name}</TableCell>
                <TableCell>{activity.details}</TableCell>
                <TableCell>{new Date(activity.date).toLocaleString()}</TableCell>
                <TableCell>{activity.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={mergedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[]}
      />
    </Paper>
  );
};

export default RecentActivity;