export const diagnosisStats = {
    totalDiagnoses: 1200,
    commonDiagnoses: ['Diabetes', 'Hypertension', 'Asthma'],
    accuracyRate: 95.2,
    diagnosisTrends: [100, 200, 150, 300, 250, 350, 400] // Example data for a chart
  };
  
  export const usageMetrics = {
    activeUsers: 150,
    sessionsPerDay: 300,
    avgSessionDuration: 45,
    sessionTrends: [50, 60, 80, 90, 70, 100, 110] // Example data for a chart
  };
  