import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';
import PatientTab from '../../../components/patientTab';
import axios from 'axios';

const Xrays = ({ patient }) => {
  const imagingResults = patient.labResults.imagingResults || [];
  console.log(imagingResults);

  let sendPic = (name) => {
    console.log(name);
    fetch("http://localhost:3002/analyze", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({file: name})
    }).then(res => res.json())
    .then(data => {
      console.log(data);
      console.log(data.data)
    })



    //////// Below is me testing sending out the images through form data. But no luck. Leaving for future reference.

    // let input = document.getElementById("fileinput");
    // input.files[0] = img;
    // let fileUpload = input.Files[0];
    // console.log(fileUpload);






    // fetch(img)
    // .then(response => response.blob())
    // .then(blob => {
    //   const url = URL.createObjectURL(blob);
      
    //   console.log("IMAGE");
    //   console.log(url);
    //   console.log(blob);
    //   // var fr = new FileReader();
    //   // fr.onload = function () {
    //   //   console.log("IMAGE");
    //   //   console.log(fr.result);
    //   //   console.log(url);
    //   // }
    //   // fr.readAsDataURL(url);
    // });


    //let fullImg = `http://localhost:3000${img}`;
    // let fullImg = img;

    // var visual_ext = fullImg.split('.').pop();
    // let visual_as_blob;
    // fetch(fullImg)
    // .then(response => response.blob())
    // .then(blob => {
    //   console.log(visual_as_blob);
    //   visual_as_blob = URL.createObjectURL(blob)
    // })
    // console.log(visual_as_blob);
    // var visual_as_file = new File([visual_as_blob], 'file-name-renamed.' + visual_ext, { lastModified: new Date().getTime(), type: visual_as_blob.type });
    // console.log(visual_as_file);




    // const form = new FormData();
    // //   form.append('file', {
    // //     upload: visual_as_file,
    // //     name: 'file.jpg',
    // //     type: 'image/jpeg',
    // // });
    // form.append("image", visual_as_file);

    // // Send other fields along with the file
    // form.append('title', 'Q1 2021');
    // form.append('description', 'An utterly interesting report');

    // // Get form headers to pass on to axios
    // // const formHeaders = form.getHeaders(); // Don't work in browser

    // // Sending a file with axios
    // axios.post("http://localhost:3456/upload", form, { headers: { 'Content-Type': 'multipart/form-data' } });
  }



  return (
    <PatientTab label="X-rays">
      <Grid container spacing={3}>
        {imagingResults.map((xray, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <Card sx={{ minHeight: '250px' }}>
              <CardMedia
                component="img"
                image={xray.imageUrl}
                alt={`X-ray taken on ${xray.date}`}
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {`X-ray taken on ${xray.date}`}
                </Typography>
                <p onClick={() => {sendPic(xray.imageName)}}>Test Radiography</p>
                {/* <Typography variant="body2" color="textSecondary" component="p">
                  {xray.description}
                </Typography> */}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </PatientTab>
  );
};

export default Xrays;
