import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import Logo from '../assets/Kuduu_Logo_Enhanced.png';

const AboutUsSection = () => {
	return (
		<Box
			sx={{
				py: 8,
				bgcolor: 'grey.100',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
			}}
			id="about"
		>
			<Container
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					maxWidth: 'md', // Restrict the width to a central size
					textAlign: 'left',

				}}
			>
				<Grid container spacing={4} alignItems="center" justifyContent="center">
					<Grid item xs={12} md={6}>
						<Typography
							variant="h3"
							gutterBottom
							sx={{
								fontWeight: 'bold',
								background: 'linear-gradient(180deg, #1E3A8A 0%, #3B82F6 100%)',
								WebkitBackgroundClip: 'text',
								WebkitTextFillColor: 'transparent',
								display: 'inline',
							}}
						>
							About Kuduu
						</Typography>
						<Typography variant="h6" sx={{ mb: 2 }}>
							At Kuduu, we are redefining healthcare with our AI-powered platform.
						</Typography>
						<Typography variant="body1" sx={{ mb: 2 }}>
							Our solution integrates with existing EHR/EMR systems to offer secure and seamless analysis of patient history, family health trends, and more.
						</Typography>
						<Typography variant="body1">
							We aim to revolutionize healthcare by bringing cutting-edge AI to the forefront.
						</Typography>
					</Grid>
					<Grid item xs={12} md={6}>
						<img
							src={Logo}
							alt="Kuduu Overview"
							style={{
								width: '100%',
								maxWidth: '300px',
								borderRadius: '8px',
								margin: '0 auto',
							}}
						/>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default AboutUsSection;
