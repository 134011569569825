import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Link as RouterLink } from 'react-router-dom';

const plans = [
    // {
    //     title: "Beta Plan",
    //     price: "Free",
    //     users: "unlimited",
    //     features: [
    //         "Access to all features"
    //     ]
    // },
  {
    title: "Small Practice Plan",
    price: "$899/month",
    users: "1-10 users",
    features: [
      "AI-Powered Health Analysis: Basic diagnostic support and insights",
      "EHR/EMR Integration: Integration with one system",
      "AI-Powered Chat Assistant: Quick responses to health questions",
      "Basic Workflow Automation: Appointment scheduling, lab result updates",
      "Basic Support: Email support with a 24-hour response time",
    ],
    addOns: [
      "Additional users: $50/user/month",
      "X-ray Process Recognition: $199/month",
      "Option to send X-rays for processing: $25 per X-ray",
    ],
  },
  {
    title: "Medium Clinic Plan",
    price: "$2,499/month",
    users: "11-50 users",
    features: [
      "Advanced AI Health Insights: Comprehensive diagnostics",
      "Multi-EHR/EMR Integration: Supports up to 3 systems",
      "Predictive Health Analysis: Proactive insights and alerts",
      "Enhanced Workflow Automation: Patient triage, follow-up reminders",
      "Priority Support: Email, chat, and phone support with 12-hour response",
    ],
    addOns: [
      "Additional users: $40/user/month",
      "Custom AI Models: $499/month",
      "24/7 Premium Support: $199/month",
    ],
  },
  {
    title: "Large Hospital Plan",
    price: "$5,999/month",
    users: "51+ users",
    features: [
      "Full AI Health Suite: AI diagnostics, predictive alerts, advanced analytics",
      "Unlimited EHR/EMR Integration: Multiple system integration",
      "Custom Workflow Automation: Tailored automation across departments",
      "24/7 Premium Support: Dedicated account manager, rapid response",
      "Advanced Reporting & Analytics: Access to comprehensive data insights",
    ],
    addOns: [
      "Custom AI Model Development: $999/month",
      "Onsite Training: $999/session",
      "Data Retention Extension: $499/month",
    ],
  },
];

const Pricing = () => {
  const [selectedAddOns, setSelectedAddOns] = useState({});

  const handleAddOnChange = (planIndex, addOn) => {
    setSelectedAddOns((prev) => ({
      ...prev,
      [planIndex]: {
        ...prev[planIndex],
        [addOn]: !prev[planIndex]?.[addOn],
      },
    }));
  };

  return (
    <Box>
      <Container>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {plans.map((plan, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card variant="outlined" sx={{ borderRadius: 1, textAlign: 'center' }}>
                <CardContent sx={{ padding: 1.5 }}>
                  <Typography variant="h6" color="#1E3A8A" fontWeight="bold" fontSize="1rem">
                    {plan.title}
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary" fontSize="0.85rem">
                    {plan.users}
                  </Typography>
                  <Typography variant="h5" color="#1E3A8A" fontWeight="bold" sx={{ mt: 0.5, fontSize: '1.25rem' }}>
                    {plan.price}
                  </Typography>
                  <Box component="ul" sx={{ listStyleType: 'none', padding: 0, mt: 1, textAlign: 'left', fontSize: '0.8rem' }}>
                    {plan.features.map((feature, idx) => (
                      <li key={idx} style={{ marginBottom: '6px', display: 'flex', alignItems: 'center' }}>
                        <CheckIcon color="primary" sx={{ mr: 0.5, fontSize: '0.9rem' }} />
                        <Typography variant="body2">{feature}</Typography>
                      </li>
                    ))}
                  </Box>
                  <Typography variant="body2" fontWeight="bold" sx={{ mt: 1, textAlign: 'left' }}>
                    Add-ons:
                  </Typography>
                  <Box sx={{ textAlign: 'left', mt: 0.5, fontSize: '0.8rem' }}>
                    {plan.addOns.map((addOn, idx) => (
                      <FormControlLabel
                        key={idx}
                        control={
                          <Checkbox
                            checked={selectedAddOns[index]?.[addOn] || false}
                            onChange={() => handleAddOnChange(index, addOn)}
                            color="primary"
                            size="small"
                          />
                        }
                        label={<Typography variant="body2">{addOn}</Typography>}
                      />
                    ))}
                  </Box>
                </CardContent>
                <CardActions sx={{ display: 'flex', justifyContent: 'center', pb: 1, pt: 0 }}>
                  <Button
                    component={RouterLink}
                    to="/signup"
                    variant="contained"
                    fullWidth
                    sx={{ bgcolor: '#1E3A8A', color: 'white', fontSize: '0.85rem', py: 0.75 }}
                  >
                    Get Started
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Pricing;
