import React, { useState, useEffect } from 'react';
import { Avatar, Box, Typography, IconButton, CircularProgress } from '@mui/material';
import { LogoutIcon } from '../assets/icons';
import { useNavigate } from 'react-router-dom';

const SidebarFooter = ({ user }) => {
  const [loggingOut, setLoggingOut] = useState(false);
  const navigate = useNavigate();

  const handleLogOut = () => {
    setLoggingOut(true); // Start the logout process
  };

  useEffect(() => {
    if (loggingOut) {
      const timer = setTimeout(() => {
        navigate('/'); // Navigate to root after a 3-second delay
      }, 4000);
      return () => clearTimeout(timer); // Clear the timer if the component unmounts
    }
  }, [loggingOut, navigate]);

  // Render "Ending experience" screen during logout
  if (loggingOut) {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={60} />
        <Typography
          variant="h6"
          sx={{
            mt: 2,
            background: 'linear-gradient(90deg, #914DDC, #FF4D4D)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Ending your experience...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderTop: '1px solid #E5E7EB', p: 2, mt: 'auto' }}>
      <Box display="flex" alignItems="center">
        <Avatar>{user?.firstName[0]}</Avatar>
        <Box ml={2}>
          <Typography variant="h6">{user?.firstName || 'Username Not Found'}</Typography>
          <Typography variant="body2" color="textSecondary">{user?.email || 'Email Not Found'}</Typography>
        </Box>
      </Box>
      <IconButton onClick={handleLogOut} color="primary" disabled={loggingOut}>
        <LogoutIcon />
      </IconButton>
    </Box>
  );
};

export default SidebarFooter;
