import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip, Typography } from '@mui/material';
import { Visibility, Launch } from '@mui/icons-material';
import PatientEditModal from './patientEditModal';

const PatientTable = ({ patients, onPatientSelect }) => {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [editingPatient, setEditingPatient] = useState(null);

  const handleViewDetails = (patient) => {
    setSelectedPatient(patient);
  };

  const handleEditDetails = (patient) => {
    setEditingPatient(patient);
  };

  const handleCloseModal = () => {
    setSelectedPatient(null);
    setEditingPatient(null);
  };

  const handleSavePatient = (updatedPatient) => {
    console.log('Saved patient data:', updatedPatient);
  };

  return (
    <TableContainer component={Paper} sx={{ width: '100%' }}>
      <Table stickyHeader>
        <TableHead sx={{ backgroundColor: '#f9fafb' }}>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Age</TableCell>
            <TableCell>Gender</TableCell>
            <TableCell>Last Visit</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ overflowY: 'auto' }}>
          {patients.map((patient) => (
            <TableRow
              key={patient.id}
              onClick={() => onPatientSelect(patient)}
              sx={{
                backgroundColor: '#FFFBFA',
                '&:hover': { backgroundColor: '#f5f5f5' },
                cursor: 'pointer',
              }}
            >
              <TableCell>{patient.id}</TableCell>
              <TableCell>{patient.name}</TableCell>
              <TableCell>{patient.age}</TableCell>
              <TableCell>{patient.gender}</TableCell>
              <TableCell>{patient.lastVisit}</TableCell>
              <TableCell>
                <Typography color={patient.status === 'Active' ? 'green' : 'red'}>
                  {patient.status === 'Active' ? 'Active' : 'Inactive'}
                </Typography>
              </TableCell>
              <TableCell>
                <Tooltip title="Quick Insight">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditDetails(patient);
                    }}
                    color="primary"
                  >
                    <Visibility />
                  </IconButton>
                </Tooltip>
                <Tooltip title="View Patient">
                  <IconButton component={Link} to={`/demo/patients/${patient.id}`} color="primary">
                    <Launch />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {editingPatient && (
        <PatientEditModal
          patient={editingPatient}
          onClose={handleCloseModal}
          onSave={handleSavePatient}
        />
      )}
    </TableContainer>
  );
};

export default PatientTable;
