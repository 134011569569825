import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DemoLoader from './splash/components/demoLoader';
import Layout from './demo/components/layout';
import Dashboard from './demo/views/dashboard/page';
import Patients from './demo/views/patients/page';
import Reports from './demo/views/reports/page';
import PatientDetails from './demo/views/patients/patientDetails';
import Integrations from './demo/views/integrations/page';
import LandingPage from './splash/views/landing/page';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/demo/dashboard" element={<DemoLoader><Layout><Dashboard /></Layout></DemoLoader>} />
        <Route path="/demo/patients" element={<DemoLoader><Layout><Patients /></Layout></DemoLoader>} />
        <Route path="/demo/patients/:id" element={<DemoLoader><Layout><PatientDetails /></Layout></DemoLoader>} />
        <Route path="/demo/reports" element={<DemoLoader><Layout><Reports /></Layout></DemoLoader>} />
        <Route path="/demo/integrations" element={<DemoLoader><Layout><Integrations /></Layout></DemoLoader>} />
      </Routes>
    </Router>
  );
}

export default App;
