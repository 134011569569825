import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, TextField, Grid, MenuItem, Button } from '@mui/material';

const PatientTab = ({ label, children }) => {
  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>{label}</Typography>
      {children}
    </Box>
  );
};

export default PatientTab;
