import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

const FeatureCard = ({ icon: Icon, title, content }) => {
    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', boxShadow: 2 }}>
            <CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        pb: 2,
                    }}
                >
                    {Icon && <Icon color="primary" sx={{ fontSize: 40, mr: 2 }} />} 
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                        {title}
                    </Typography>
                </Box>
                <ul style={{ paddingLeft: '20px', margin: 0 }}>
                    {content.map((item, index) => (
                        <li key={index}>
                            <Typography variant="body2">{item}</Typography>
                        </li>
                    ))}
                </ul>
            </CardContent>
        </Card>
    );
};

export default FeatureCard;
