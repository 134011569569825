
import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Paper } from '@mui/material';
import PatientTab from '../../../components/patientTab';

const Allergies = ({ patient }) => {
  const allergies = patient.allergies || [];

  return (
    <PatientTab label="Allergies">
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>Allergy Information</Typography>
        <List>
          {allergies.map((allergy, index) => (
            <ListItem key={index}>
              <ListItemText primary={allergy} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </PatientTab>
  );
};

export default Allergies;
