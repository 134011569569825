import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Paper, Typography, Tab, Tabs, Avatar, Grid, IconButton } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { patientsData } from '../../data/patientData';
import GeneralInfo from './tabs/generalInfo';
import Medication from './tabs/medicationTab';
import Xrays from './tabs/xrays';
import Allergies from './tabs/allergies';
import Lifestyle from './tabs/lifeStyle';
import MedicalHistory from './tabs/medicalHistory';
import LabResults from './tabs/labResults';
import MentalHealth from './tabs/mentalHealth';
import Insight from './tabs/insight';

const PatientDetails = () => {
  const { id } = useParams();  // Use id instead of name
  const navigate = useNavigate();
  const patient = patientsData.find((p) => p.id === id);  // Find patient by id
  const [activeTab, setActiveTab] = useState(0);

  if (!patient) {
    return <Typography variant="h5">Patient not found</Typography>;
  }

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabs = [
    { label: 'General Information', component: <GeneralInfo patient={patient} /> },
    { label: 'Medical History', component: <MedicalHistory patient={patient} /> },
    { label: 'Mental Health', component: <MentalHealth patient={patient} /> },
    { label: 'Medications', component: <Medication patient={patient} /> },
    { label: 'X-rays', component: <Xrays patient={patient} /> },
    { label: 'Lab Results', component: <LabResults patient={patient} setActiveTab={setActiveTab} /> },
    { label: 'Allergies', component: <Allergies patient={patient} /> },
    { label: 'Lifestyle', component: <Lifestyle patient={patient} /> },
    { label: 'Insight', component: <Insight patient={patient} /> },
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 2, mb: 3, position: 'relative' }}>
        <IconButton
          onClick={() => navigate('/patients')}
          sx={{ 
            position: 'absolute', 
            top: 8, 
            right: 8, 
            color: 'red' 
          }}
        >
          <CloseOutlinedIcon />
        </IconButton>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <Avatar src={patient.avatar} alt={patient.name} sx={{ width: 100, height: 100 }} />
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography variant="h4">{patient.name}</Typography>
            <Typography variant="h6">ID: {patient.id}</Typography>
            <Typography variant="h6">Age: {patient.age}</Typography>
            <Typography variant="h6">Gender: {patient.gender}</Typography>
            <Typography variant="h6">Last Visit: {patient.lastVisit}</Typography>
            <Typography variant="h6">Status: {patient.status}</Typography>
          </Grid>
        </Grid>
        <Tabs value={activeTab} onChange={handleChange} aria-label="patient details tabs" sx={{ mt: 2 }}>
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>
      </Paper>
      {tabs[activeTab].component}
    </Box>
  );
};

export default PatientDetails;
