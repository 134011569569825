import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography, Box, Zoom } from '@mui/material';

const DemoLoader = ({ children }) => {
  const [loading, setLoading] = useState(!sessionStorage.getItem('demoEntered')); // Check flag

  useEffect(() => {
    if (!loading) return; // If already not loading, skip the rest

    const timer = setTimeout(() => {
      setLoading(false);
      sessionStorage.setItem('demoEntered', 'true'); // Set flag after loading completes
    }, 4000);

    return () => clearTimeout(timer);
  }, [loading]);

  return loading ? (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={60} />
      <Typography 
        variant="h6"
        sx={{
          mt: 2,
          background: 'linear-gradient(90deg, #914DDC, #FF4D4D)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        Loading your sandbox experience...
      </Typography>
    </Box>
  ) : (
    <Zoom in={true} timeout={500}>
      <Box sx={{ width: '100%' }}>{children}</Box>
    </Zoom>
  );
};

export default DemoLoader;
