import React, { useState } from 'react';
import axios from 'axios';
import {
	Box,
	Grid,
	Typography,
	Link,
	Button,
	Divider,
	TextField,
	CircularProgress,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { useMediaQuery, useTheme } from '@mui/material';

const Footer = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile
	const captchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
	const emailEndpoint = process.env.REACT_APP_EMAIL_ENDPOINT;

	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		organization: '',
		inquiry: '',
	});
	const [errors, setErrors] = useState({});
	const [message, setMessage] = useState('');
	const [messageColor, setMessageColor] = useState('');
	const [loading, setLoading] = useState(false); // State to control CircularProgress

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
		setErrors((prevErrors) => ({ ...prevErrors, [name]: '' })); // Clear error on change
	};

	const validateForm = () => {
		let newErrors = {};
		if (!formData.firstName) newErrors.firstName = 'First name is required';
		if (!formData.lastName) newErrors.lastName = 'Last name is required';
		if (!formData.email) newErrors.email = 'Email is required';
		if (!formData.inquiry) newErrors.inquiry = 'Inquiry is required';

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0; // Returns true if no errors
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (validateForm()) {
			setLoading(true); // Start loading
			try {
				// Generate a new captcha token
				const token = await new Promise((resolve, reject) => {
					window.grecaptcha
						.execute(captchaSiteKey, { action: 'submit' })
						.then(resolve)
						.catch(reject);
				});

				// Ensure that the token is defined
				if (!token) {
					setErrors({ captcha: 'Please verify that you are not a robot.' });
					setLoading(false); // Stop loading if captcha fails
					return;
				}

				// Prepare form data with captcha token
				const submissionData = {
					firstName: formData.firstName,
					lastName: formData.lastName,
					email: formData.email,
					organization: formData.organization,
					inquiry: formData.inquiry,
					captcha: token,
				};

				// Submit form data to backend
				const response = await axios.post(
					`${emailEndpoint}/contact`,
					submissionData,
					{
						headers: {
							'Content-Type': 'application/json',
						},
					}
				);
				console.log('Response:', response.data);
				setMessage(response.data); // Set success message
				setMessageColor('#1E3A8A'); // Success color
				setFormData({
					firstName: '',
					lastName: '',
					email: '',
					organization: '',
					inquiry: '',
				});
			} catch (error) {
				if (error.response && error.response.status === 400) {
					setMessage(error.response.data); // Use server error message
					setMessageColor('#FF0000'); // Error color
				} else {
					console.error('Error:', error);
					setMessage('An error occurred. Please try again later.');
					setMessageColor('#FF0000');
				}
			} finally {
				setLoading(false); // Stop loading once the request completes
			}
		}
	};

	return (
		<Box component="footer" sx={{ py: 4, width: '100%' }}>
			<Grid
				container
				spacing={2}
				sx={{ justifyContent: 'center', padding: isMobile ? 2 : 0 }}
			>
				{/* About Kuduu Section */}
				<Grid
					item
					xs={12}
					md={3}
					sx={{
						padding: 4,
						paddingLeft: isMobile ? 2 : 4,
						paddingRight: isMobile ? 2 : 4,
						textAlign: 'left',
					}}
				>
					<Typography
						variant="h6"
						gutterBottom
						color="#1E3A8A"
						fontWeight="bold"
					>
						About Kuduu
					</Typography>
					<Typography variant="body2">
						Kuduu simplifies health data analysis with AI, helping healthcare
						professionals make{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6">
							informed decisions
						</Box>
						. Our platform seamlessly integrates with existing EHR/EMR systems,
						providing{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6">
							AI-driven insights
						</Box>{' '}
						to enhance patient care.
					</Typography>
					<br />
					<Typography
						variant="body2"
						fontWeight="bold"
						gutterBottom
						color="#1E3A8A"
					>
						Key Features:
					</Typography>
					<Typography variant="body2">
						•{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6">
							Seamless Integration
						</Box>
					</Typography>
					<Typography variant="body2">
						•{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6">
							AI-driven Insights
						</Box>
					</Typography>
					<Typography variant="body2">
						•{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6">
							User-Friendly Interface
						</Box>
					</Typography>
					<br />
					<Typography
						variant="body2"
						fontWeight="bold"
						gutterBottom
						color="#1E3A8A"
					>
						Mission:
					</Typography>
					<Typography variant="body2">
						To{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6">
							revolutionize patient care and outcomes
						</Box>{' '}
						through AI-powered solutions.
					</Typography>
					<br />
					<Typography
						variant="body2"
						fontWeight="bold"
						gutterBottom
						color="#1E3A8A"
					>
						Security & Privacy:
					</Typography>
					<Typography variant="body2">
						Kuduu is committed to the{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6">
							highest standards of data privacy and security
						</Box>
						. We handle all patient data with{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6">
							strict anonymization and encryption
						</Box>{' '}
						to protect confidentiality, in compliance with healthcare and
						regional regulations. Our platform also follows{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6">
							industry security standards
						</Box>{' '}
						across both healthcare and technology sectors.
					</Typography>
				</Grid>

				<Divider
					orientation="vertical"
					flexItem
					sx={{ margin: 2, display: isMobile ? 'none' : 'block' }}
				/>

				{/* Collaborate with Us Section */}
				<Grid
					item
					xs={12}
					md={3}
					sx={{
						paddingLeft: isMobile ? 2 : 4,
						paddingRight: isMobile ? 2 : 4,
						textAlign: 'left',
					}}
				>
					<Typography
						variant="h6"
						gutterBottom
						color="#1E3A8A"
						fontWeight="bold"
					>
						Collaborate with Us
					</Typography>
					<Typography variant="body2">
						At Kuduu, we believe that{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6 ">
							collaboration is key
						</Box>{' '}
						to advancing healthcare solutions. We invite medical professionals
						and organizations to partner with us in our mission to{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6 ">
							revolutionize health data analysis
						</Box>
						.
					</Typography>
					<br />
					<Typography variant="body2">
						Your{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6 ">
							expertise and insights
						</Box>{' '}
						are invaluable as we strive to develop software that meets the needs
						of healthcare providers. Together, we can create a platform that
						enhances patient care through{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6 ">
							AI-driven insights
						</Box>{' '}
						and{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6 ">
							innovative technology
						</Box>
						.
					</Typography>
					<br />
					<Typography variant="body2">
						Kuduu values the perspectives and expertise of healthcare
						professionals at every level. By working together, we can ensure
						that our tools and insights align with the{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6 ">
							realities of patient care
						</Box>
						, making each feature both{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6 ">
							impactful
						</Box>{' '}
						and{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6 ">
							intuitive
						</Box>
						. Our team is committed to a partnership that not only empowers
						providers but also evolves based on your feedback, fostering a{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6 ">
							truly responsive platform
						</Box>{' '}
						tailored to the ever-changing landscape of healthcare.
					</Typography>
					<br />
					<Typography variant="body2">
						Our collaborative approach is designed to{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6 ">
							amplify the strengths of all stakeholders
						</Box>
						, creating synergies that drive{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6 ">
							continuous improvement
						</Box>
						. From large healthcare systems to individual practices, Kuduu’s
						platform is{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6 ">
							versatile and adaptive
						</Box>
						, providing{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6 ">
							scalable solutions
						</Box>{' '}
						that address diverse clinical needs. Let’s work together to leverage
						technology that{' '}
						<Box component="span" fontWeight="bold" color="#3B82F6">
							maximizes patient outcomes
						</Box>{' '}
						and supports a more informed, connected healthcare community.
					</Typography>
				</Grid>
				<Divider
					orientation="vertical"
					flexItem
					sx={{ margin: 2, display: isMobile ? 'none' : 'block' }}
				/>

				{/* Contact Us Section */}
				<Grid
					item
					xs={12}
					md={3}
					sx={{
						paddingLeft: isMobile ? 2 : 4,
						paddingRight: isMobile ? 2 : 4,
						textAlign: 'left',
					}}
				>
					<Typography
						variant="h6"
						gutterBottom
						color="#1E3A8A"
						fontWeight="bold"
					>
						Contact Us
					</Typography>
					<Typography variant="body2">
						<Link href="mailto:info@kuduu.io">
							General Inquiries: info@kuduu.io
						</Link>
					</Typography>
					<Typography variant="body2">
						<Link href="mailto:support@kuduu.io">
							Support: support@kuduu.io
						</Link>
					</Typography>

					<form onSubmit={handleSubmit}>
						<Grid container spacing={1}>
							<Grid item xs={6}>
								<TextField
									margin="normal"
									name="firstName"
									label="First Name"
									fullWidth
									variant="standard"
									size="small"
									value={formData.firstName}
									onChange={handleInputChange}
									error={!!errors.firstName}
									helperText={errors.firstName}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									margin="normal"
									name="lastName"
									label="Last Name"
									fullWidth
									variant="standard"
									size="small"
									value={formData.lastName}
									onChange={handleInputChange}
									error={!!errors.lastName}
									helperText={errors.lastName}
								/>
							</Grid>
						</Grid>
						<TextField
							margin="normal"
							name="email"
							label="Email"
							fullWidth
							variant="standard"
							size="small"
							value={formData.email}
							onChange={handleInputChange}
							error={!!errors.email}
							helperText={errors.email}
						/>
						<TextField
							margin="normal"
							name="organization"
							label="Organization (if applicable)"
							fullWidth
							variant="standard"
							size="small"
							value={formData.organization}
							onChange={handleInputChange}
						/>
						<TextField
							margin="normal"
							name="inquiry"
							label="Inquiry"
							fullWidth
							variant="standard"
							size="small"
							value={formData.inquiry}
							onChange={handleInputChange}
							multiline
							rows={2}
							error={!!errors.inquiry}
							helperText={errors.inquiry}
						/>
						<Button
							type="submit"
							variant="contained"
							sx={{
								backgroundColor: '#1E3A8A',
								color: 'white',
								fontWeight: 'bold',
								padding: '0.5rem 1rem',
								mt: 2,
							}}
							disabled={loading} // Disable button while loading
						>
							Submit <EmailIcon sx={{ marginLeft: '0.5rem' }} />
							{loading && <CircularProgress size={20} sx={{ ml: 1 }} />}
						</Button>
					</form>

					{message && (
						<Typography
							variant="body2"
							style={{ color: messageColor, marginTop: '1rem' }}
						>
							{message}
						</Typography>
					)}
				</Grid>
			</Grid>

			{/* Footer Disclaimer */}
			<Typography variant="body2" sx={{ mt: 4, textAlign: 'center' }}>
				&copy; {new Date().getFullYear()} Kuduu Inc. All rights reserved.
			</Typography>
			<Typography
				variant="body2"
				sx={{
					mt: 2,
					textAlign: 'center',
					color: 'text.secondary',
					fontStyle: 'italic',
					fontSize: '0.8rem',
				}}
			>
				Disclaimer: Kuduu is an AI-driven health analysis tool designed to
				assist healthcare professionals in making informed decisions.
				<br />
				While Kuduu provides valuable insights, it is not intended to replace
				the clinical judgment of qualified health providers.
				<br />
				Always use your professional expertise when interpreting results and
				making patient care decisions.
			</Typography>
		</Box>
	);
};

export default Footer;
