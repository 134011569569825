import React from 'react';
import { Box, Grid, Card, CardContent, Typography, Button, IconButton } from '@mui/material';
import { integrations as initialIntegrations } from '../../data/integrations';
import { styled } from '@mui/system';
import LinkOffIcon from '@mui/icons-material/LinkOff';

const StyledCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  padding: '16px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
});

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
});

const StyledButton = styled(Button)({
  marginTop: '16px',
});

const Integrations = () => {
  // Use the status from the integrations array for demo purposes
  const integrations = initialIntegrations.map((integration) => ({
    ...integration,
    isConnected: integration.status === 'Connected', // Directly use the status for demo
  }));

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Integrations
      </Typography>
      <Grid container spacing={10}>
        {integrations.map((integration, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <StyledCard>
              <StyledCardContent>
                <img src={integration.logo} alt={`${integration.name} logo`} width={100} height={75} />
                <Typography variant="h6" sx={{ mt: 2 }}>{integration.name}</Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  {integration.description}
                </Typography>
              </StyledCardContent>
              {integration.isConnected ? (
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="body2" color="primary">Connected</Typography>
                  <IconButton
                    color="secondary"
                    sx={{ mt: 1 }}
                  >
                    <LinkOffIcon />
                  </IconButton>
                </Box>
              ) : (
                <StyledButton
                  variant="contained"
                  color="primary"
                >
                  Connect
                </StyledButton>
              )}
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Integrations;