import React from 'react';
import { Box } from '@mui/material';
import Sidebar from './sidebar';
import SidebarFooter from './sidebarFooter';

const demoUser = {
  firstName: 'Demo',
  lastName: 'User',
  email: 'demo@kuduu.io',
};

const Layout = ({ children, setLoggingOut }) => {
  return (
    <Box sx={{ display: 'flex', width: '100vw', height: '100vh' }}>
      <Box sx={{ width: '240px' }}>
        <Sidebar user={demoUser} />
        <SidebarFooter user={demoUser} setLoggingOut={setLoggingOut} /> {/* Pass setLoggingOut here */}
      </Box>
      <Box sx={{ flexGrow: 1, padding: 3, position: 'relative' }}>
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
