import React from 'react';
import { Box, TextField, Typography } from '@mui/material';

const AccountCreation = () => (
  <Box>
    <Typography variant="h6" gutterBottom>
      Create Your Account
    </Typography>
    <TextField label="Email" fullWidth margin="normal" />
    <TextField label="Password" type="password" fullWidth margin="normal" />
    <TextField label="Confirm Password" type="password" fullWidth margin="normal" />
    {/* Additional fields as needed */}
  </Box>
);

export default AccountCreation;
