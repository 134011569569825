import React, { useState } from 'react';
import { Box, Container, Typography, Button, CircularProgress } from '@mui/material';
import ProgressStepper from '../../components/progressStepper';
import AccountCreation from './steps/accountCreation';
import OrganizationInfo from './steps/organizationInfo';
import FeatureSelection from './steps/featureSelection';
import IntegrationSetup from './steps/integrationSetup';
import PricingSelection from './steps/pricingPlanSelection';

const steps = ['Account Creation', 'Organization Info', 'Feature Selection', 'Integration Setup', 'Pricing Selection'];

const Onboarding = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setActiveStep((prevStep) => prevStep + 1);
    }, 500); // Simulate a delay for loading
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <AccountCreation />;
      case 1:
        return <OrganizationInfo />;
      case 2:
        return <PricingSelection />;
      case 3:
        return <IntegrationSetup />;
      default:
        return 'Unknown step';
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ width: '100%', mt: 4 }}>
        <ProgressStepper steps={steps} activeStep={activeStep} />

        <Box sx={{ mt: 4, mb: 2 }}>
          {activeStep === steps.length ? (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h5">Onboarding Complete!</Typography>
              <Button onClick={() => setActiveStep(0)} sx={{ mt: 2 }}>
                Reset
              </Button>
            </Box>
          ) : (
            <>
              <Box>{getStepContent(activeStep)}</Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                <Button disabled={activeStep === 0 || loading} onClick={handleBack}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={loading}
                  endIcon={loading ? <CircularProgress size={20} /> : null}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Onboarding;
