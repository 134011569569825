import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

const PatientEditModal = ({ patient, onClose, onSave }) => {
  const [updatedPatient, setUpdatedPatient] = React.useState(patient);

  const handleChange = (e) => {
    setUpdatedPatient({ ...updatedPatient, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    onSave(updatedPatient);
    onClose();
  };

  return (
    <Dialog open={Boolean(patient)} onClose={onClose}>
      <DialogTitle>Edit Patient</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Name"
          name="name"
          value={updatedPatient.name}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Age"
          name="age"
          value={updatedPatient.age}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Gender"
          name="gender"
          value={updatedPatient.gender}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Last Visit"
          name="lastVisit"
          value={updatedPatient.lastVisit}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Status"
          name="status"
          value={updatedPatient.status}
          onChange={handleChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PatientEditModal;
