import React, { useState } from 'react';
import { Box, Typography, Container } from '@mui/material';
import SearchBar from '../../components/patientSearchBar';
import PatientTable from '../../components/patientTable';
import HeaderBox from '../../components/headerBox';
import { patientsData } from '../../data/patientData';

const PatientsPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPatient, setSelectedPatient] = useState(null);

  const filteredPatients = patientsData.filter((patient) =>
    patient.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handlePatientSelect = (patient) => {
    setSelectedPatient(patient);
  };

  const handleSavePatient = (updatedPatient) => {
    console.log('Saved patient data:', updatedPatient);
  };

  return (
    <Container maxWidth={false} sx={{ paddingTop: 4, paddingBottom: 4 }}>
      <HeaderBox title="Patients" subtext="Effortlessly manage your patient records." />
      <SearchBar value={searchTerm} onChange={setSearchTerm} />
      <Box mt={4}>
        <Typography variant="h5">Patient List</Typography>
        <Box sx={{ width: '100%' }}> {/* Ensures full width for the table container */}
          <PatientTable patients={filteredPatients} onPatientSelect={handlePatientSelect} />
        </Box>
      </Box>
    </Container>
  );
};

export default PatientsPage;
