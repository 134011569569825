import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import MemoryIcon from '@mui/icons-material/Memory';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import AllOutIcon from '@mui/icons-material/AllOut';
import FeatureCard from './featureCard';

const FeaturesSection = () => {
	return (
		<Box sx={{ py: 8, bgcolor: 'grey.100' }} id="features">
			<Container>
				<Typography
					variant="h3"
					align="center"
					gutterBottom
					sx={{
						fontWeight: 'bold',
						background: 'linear-gradient(180deg, #1E3A8A 0%, #3B82F6 100%)',
						WebkitBackgroundClip: 'text',
						WebkitTextFillColor: 'transparent',
						fontWeight: 'bold',
					}}
				>
					Our Features
				</Typography>
				<Grid container spacing={4}>
					<Grid item xs={12} md={4}>
						<FeatureCard
							icon={MemoryIcon}
							title="Automated"
							content={[
								'Personalized Interaction: Tailors insights to each healthcare professional’s workflow.',
								'Task Automation: Reduces manual work by automating tasks and offering recommendations.',
								'Contextual Awareness: Processes real-time health data for quick, accurate decision-making.',
							]}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<FeatureCard
							icon={ViewModuleIcon}
							title="Modular"
							content={[
								'Customizable Modules: Choose the features you need, from data analysis to trend detection.',
								'Seamless Integration: Works smoothly with existing EHR/EMR systems without disruption.',
								'Flexible Upgrades: Add new modules as needs evolve, ensuring future-ready functionality.',
							]}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<FeatureCard
							icon={AllOutIcon}
							title="Scalable"
							content={[
								'Growth-Ready Architecture: Supports growing practices and expanding data without performance loss.',
								'Cloud-Based: Scales horizontally and vertically to handle large data volumes and users.',
								'Adaptive Performance: Fits the needs of any organization, from small clinics to large hospitals.',
							]}
						/>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default FeaturesSection;
