import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import DiagnosisStatistics from './diagnosticStatistics';
import UsageMetrics from './usageMetrics';
import { diagnosisStats, usageMetrics } from '../data/metricData';

const MetricBox = () => {
	return (
		<>
			<Paper
			sx={{
				p: 2,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				// background: 'linear-gradient(90deg, #1E3A8A 0%, #3B82F6 100%)',
				color: 'black',
				height: '100%',
				'& h5': {
					fontSize: '1.5rem',
					fontWeight: 'bold',
					marginBottom: '1rem',
				},
			}}
			>
				<Box>
					<Grid container spacing={4}>
						<Grid item xs={6} md={6}>
							<DiagnosisStatistics
								totalDiagnoses={diagnosisStats.totalDiagnoses}
								commonDiagnoses={diagnosisStats.commonDiagnoses}
								accuracyRate={diagnosisStats.accuracyRate}
								diagnosisTrends={diagnosisStats.diagnosisTrends}
							/>
						</Grid>
						<Grid item xs={6} md={6}>
							<UsageMetrics
								activeUsers={usageMetrics.activeUsers}
								sessionsPerDay={usageMetrics.sessionsPerDay}
								avgSessionDuration={usageMetrics.avgSessionDuration}
								sessionTrends={usageMetrics.sessionTrends}
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};

export default MetricBox;
