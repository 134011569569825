import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import FeatureCard from './featureCard';
import InputIcon from '@mui/icons-material/Input';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssistantIcon from '@mui/icons-material/Assistant';

const CallToActionSection = () => {
	return (
		<Box sx={{ py: 8 }} id="process">
			<Container>
				<Typography variant="h3" align="center" gutterBottom>
					<span
						style={{
							background: 'linear-gradient(180deg, #1E3A8A 0%, #3B82F6 100%)',
							WebkitBackgroundClip: 'text',
							WebkitTextFillColor: 'transparent',
							display: 'inline',
							fontWeight: 'bold',
						}}
					>
						{' '}
						Supercharge{' '}
					</span>{' '}
					Your Practice
				</Typography>
				<Typography align="center">
					Providing real-time insights and intelligent tools to enhance patient
					care.
				</Typography>
				<Grid container spacing={4} sx={{ mt: 4 }} alignItems="stretch">
					<Grid item xs={12} md={3}>
						<FeatureCard
							icon={InputIcon}
							title="Data Ingestion"
							content={[
								'Securely connects to EMR/EHR systems in real-time.',
								'Pulls and imports patient and clinical data seamlessly.',
								'Adheres to healthcare data security standards and regulations.',
							]}
						/>
					</Grid>
					<Grid item xs={12} md={3}>
						<FeatureCard
							icon={AccountTreeIcon}
							title="Data Processing"
							content={[
								'Cleanses and organizes data for accurate analysis.',
								'Automates data structuring using advanced techniques.',
								'Prepares processed data for in-depth and insightful analysis, enabling accurate decision-making.',
							]}
						/>
					</Grid>
					<Grid item xs={12} md={3}>
						<FeatureCard
							icon={SettingsSuggestIcon}
							title="ML Process"
							content={[
								'Identifies patterns and trends through machine learning algorithms.',
								'Provides predictive insights based on real-time data.',
								'Continuously improves with self-learning capabilities for better outcomes.',
							]}
						/>
					</Grid>
					<Grid item xs={12} md={3}>
						<FeatureCard
							icon={AssistantIcon}
							title="Agent Tools"
							content={[
								'Utilizes digital twin tech to create patient health models.',
								'Offers real-time health insights and recommendations.',
								'Supports virtual patient monitoring for better care.',
							]}
						/>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default CallToActionSection;
