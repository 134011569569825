import React, { useState } from 'react';
import { AppBar, Toolbar, Button, IconButton, Box, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../assets/Kuduu_Logo_Enhanced.png';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Header = ({ toggleDrawer, handleScroll }) => {
    const [selectedSection, setSelectedSection] = useState(''); // Track selected section
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Function to handle scroll and set active section
    const handleSectionClick = (sectionId) => {
        handleScroll(sectionId);
        setSelectedSection(sectionId); // Set selected section
    };

    return (
        <AppBar position="fixed" color="default">
            <Toolbar>
                <img src={Logo} alt="KUDUU Logo" style={{ height: 50, width: 50 }} />
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, ml: 2 }}>
                    KUDUU
                </Typography>
                {isMobile ? (
                    <IconButton color="inherit" edge="end" onClick={toggleDrawer(true)}>
                        <MenuIcon />
                    </IconButton>
                ) : (
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
                        <Button
                            color="inherit"
                            onClick={() => handleSectionClick('features')}
                            sx={{ color: selectedSection === 'features' ? 'red' : 'inherit' }}
                        >
                            Features
                        </Button>
                        <Button
                            color="inherit"
                            onClick={() => handleSectionClick('process')}
                            sx={{ color: selectedSection === 'process' ? 'red' : 'inherit' }}
                        >
                            How It Works
                        </Button>
                        <Button
                            color="inherit"
                            onClick={() => handleSectionClick('about')}
                            sx={{ color: selectedSection === 'about' ? 'red' : 'inherit' }}
                        >
                            About
                        </Button>
                        {/* <Button variant="contained" href="/signup">
                            Get Started
                        </Button> */}
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
