import React, { useState } from 'react';
import { Drawer, Box, List, ListItem, ListItemText, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DrawerComponent = ({ drawerOpen, toggleDrawer, handleScroll }) => {
    const [selectedSection, setSelectedSection] = useState('');

    const handleItemClick = (sectionId) => {
        handleScroll(sectionId);
        setSelectedSection(sectionId); // Track selected section
    };

    return (
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
            <Box 
                sx={{ 
                    width: 250, 
                    bgcolor: '#1E3A8A', 
                    color: 'white',
                    height: '100%',
                    position: 'relative'
                }} 
                role="presentation" 
                onClick={toggleDrawer(false)} 
                onKeyDown={toggleDrawer(false)}
            >
                <IconButton 
                    onClick={toggleDrawer(false)} 
                    sx={{ 
                        position: 'absolute', 
                        top: 10, 
                        right: 10, 
                        color: 'red'
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <List sx={{ pt: 4 }}>
                    <ListItem 
                        button 
                        onClick={() => handleItemClick('features')}
                        sx={{ color: selectedSection === 'features' ? 'red' : 'white' }}
                    >
                        <ListItemText primary="Features" />
                    </ListItem>
                    <ListItem 
                        button 
                        onClick={() => handleItemClick('process')}
                        sx={{ color: selectedSection === 'process' ? 'red' : 'white' }}
                    >
                        <ListItemText primary="How It Works" />
                    </ListItem>
                    <ListItem 
                        button 
                        onClick={() => handleItemClick('about')}
                        sx={{ color: selectedSection === 'about' ? 'red' : 'white' }}
                    >
                        <ListItemText primary="About" />
                    </ListItem>
                    {/* <ListItem 
                        button 
                        component="a" 
                        href="/signup"
                        sx={{ color: 'white' }}
                    >
                        <ListItemText primary="Get Started" />
                    </ListItem> */}
                </List>
            </Box>
        </Drawer>
    );
};

export default DrawerComponent;
